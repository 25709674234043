import React from 'react'

import styles from './PrivacyPolicy.module.scss'
import LayoutText from 'pages/layouts/LayoutText'

function PrivacyPolicy() {
  return (
    <LayoutText title='PRIVACY POLICY' date='August, 2023'>
      <div className={styles.privacyWrapper}>
        <p>
          This Privacy Policy describes how FinWhale Labs, LLC (
          <span className='text-hightlight'>
            "Rover Seals”, "we", "our", and "us"
          </span>
          ) collects, uses, processes, discloses, shares, transfers, and
          protects your personal information or data when you (“you”, “your"): 
          <br />
          <br />
          (a) access or use Rover Seals Website (
          <span className='text-hightlight'>“Site”</span>) provided on or in
          connection with Services, as described in our Terms of Use
          (collectively, the <span className='text-hightlight'>“Services”</span>
          ); and
          <br />
          <br />
          (b) provide us with your personal data.
          <br />
          <br />
          This Privacy Policy (together with our Terms of Use and any other
          documents referred to herein) sets out the basis on which any personal
          data we collect from you, or that you provide to us, will be
          processed, used and/or disclosed by us. Please read the following
          carefully to understand our practices in processing your personal
          data, as well as your rights regarding your personal data and how we
          will treat it.
          <br />
          <br />
          By visiting the Site and/or submitting data to us, you are accepting
          and consenting to the collection, use, disclosure and processing of
          your personal data as described in this Privacy Policy. Please refrain
          from accessing the Site and do not provide any personal data to us if
          you disagree with the terms of this Privacy Policy.
          <br />
          <br />
          We may change or amend this Privacy Policy from time to time without
          notice to you, in compliance with applicable laws or as we update our
          data usage and handling processes. Any changes we may make to this
          Privacy Policy in the future will be made available on the Site and
          any such changes will become effective upon posting of the revised
          Privacy Policy. The updated Privacy Policy will supersede earlier
          versions and will apply to personal data provided to us previously.
          Please check back frequently to see any updates or changes to our
          Privacy Policy. 
          <br />
          <br />
          For the avoidance of doubt, this Privacy Policy forms a part of the
          terms and conditions governing your relationship with us. This Privacy
          Policy supplements but does not supersede or replace any other
          consents you may have provided to us, or any other agreements or
          arrangements that you may have with us, in respect of your personal
          data.
          <br />
          <br />
          For any questions or feedback in relation to this Privacy Policy or
          your personal data, or if you wish to make a complaint to us, please
          submit your requests, feedback or complaint to{' '}
          <a href='mailto:legal@finwallet.com' className='text-hightlight'>
            legal@finwallet.com
          </a>
          .
          <br />
          <br />
           When you contact us, we may require that you submit certain forms or
          provide certain information, including verification of your identity,
          before we are able to respond.
        </p>

        <>
          <div className='sub-text'>1. Your Personal Data </div>

          <p>
            <span className='text-hightlight'>“Personal data”</span> is data
            that can be used to identify a natural person. Examples of personal
            data include name, transactional information based on your
            activities on our Site and/or Services, and any other information of
            a personal nature. 
            <br />
            <br />
            We may collect and process personal data provided directly by you.
            We also process, insofar as necessary, personal data provided to us
            by third parties, including publicly accessible data, personal data
            legitimately provided by other group companies, personal data
            collected through your use of our (or our service provider’s)
            digital technologies and services, and personal data provided by
            other trusted third parties (including our service providers). 
            <br />
            <br />
            You are responsible for ensuring that all personal data that you
            provide to us is true, accurate and complete.  You are responsible
            for informing us of any changes to your data in writing, so that we
            may take all reasonable measures to keep our records of your
            personal data correct and up to date. 
            <br />
            <br />
            When our collection is based on consent, you have the choice, at any
            time, not to provide your personal data to us or to withdraw your
            consent previously provided to us. However, do note that if you do
            so, we may be unable to fulfill the purposes for which we require
            the personal data, continue to provide Services to you (e.g.
            processing your transactions), and/or fulfill any contractual
            relationship which may be in place between us.
            <br />
            <br />
            Our Site and/or Services are not intended to be accessed or used by
            children, minors or persons who are not of legal age. If you are a
            parent or guardian and you have reason to believe your child or ward
            has provided us with their personal data without your consent,
            please contact us.
            <br />
            <br />
            In certain circumstances, you may also provide us with personal data
            of persons other than yourself (such as your officers, employees,
            agents, customers, family members and next-of-kin). If you do so,
            you represent and warrant that you have brought this Privacy Policy
            to his/her attention, informed him/her of the purposes for which we
            are collecting his/her personal data and that he/she has consented
            to your disclosure of his/her personal data to us for those purposes
            and accepts this Privacy Policy. You agree to indemnify and hold us
            harmless from and against any and all claims by such individuals
            relating to our collection, use and disclosure of such personal data
            in accordance with the terms of this Privacy Policy.
          </p>
        </>

        <>
          <div className='sub-text'>1.1 Personal Data you give us</div>

          <p>
            You may voluntarily give us your personal data by filling in forms
            on the Site or by corresponding with us by phone, e-mail or
            otherwise. This includes personal data you provide when you register
            on the Site, use our Services or the services provided by our
            service providers, or when you report a problem with the Site. See
            Clause 1.4 for more information on how we collect your personal
            data.  
            <br />
            <br />
            Examples of personal data you may provide to us include (depending
            on the nature of your interaction with us) your name, email address,
            and any other information relating to any individuals which you have
            provided to us in any forms submitted to us, or via other forms of
            interaction with you. See Clause 1.2 for more information on the
            types of personal data we may collect from you.
          </p>
        </>

        <>
          <div className='sub-text'>
            1.2 Personal data and/or information we collect about you 
          </div>

          <p>
            With regard to each of your visits to or use of our Site, we may
            collect the following information and/or personal data that are
            relevant to our relationship with you, as well as copies of
            documents verifying such information:
            <br />
            <br />
            (a) your financial information, including but not limited to, your
            transaction history , your investment details, and any other
            information or documents in order to comply with domestic and
            international industry standards, government rules and regulations,
            particularly Anti-Money Laundering (AML) regulations, Know Your
            Customer (KYC) rules, and Counter-Terrorist Financing (CTF)
            regulations;
            <br />
            <br />
            (b) technical information, including but not limited to the Internet
            protocol (IP) address used to connect your computer to the Internet,
            your login information, browser type and version, time zone setting,
            browser plug-in types and versions, and information relating to your
            operating system and platform;
            <br />
            <br />
            (c) information about your visit and use of our Site, including but
            not limited to the full Uniform Resource Locators (URL) clickstream
            to, through and from our Site (including date and time), download
            errors, length of visits to certain pages, page interaction
            information (such as scrolling, clicks, and mouse-overs), and
            methods used to browse away from the page; and
            <br />
            <br />
            You hereby agree that we are entitled to collect and use the
            above-listed personal data and/or information you have provided on
            our Site or generated through your use or access of our Site for the
            purposes listed in Clause 2 of this Privacy Policy. 
            <br />
            <br />
            Apart from personal data, we may collect other types of information
            which are not related to an individual and which are anonymous. For
            example, the number of website visitors and the number of website
            users using a particular service.
          </p>
        </>

        <>
          <div className='sub-text'>1.3 How we collect your personal data </div>

          <p>
            We may collect your personal data directly or indirectly through
            various channels, such as:
            <br />
            <br />
            (a) when you register an account or wallet with us through our Site;
            <br />
            <br />
            (b) when you log in, visit, use, access or download our Site and
            Services;
            <br />
            <br />
            (c) when you authorize us to obtain your personal data from a third
            party; 
            <br />
            <br />
            (d) when you enter into agreements with us; 
            <br />
            <br />
            (e) when you transact with us, contact us or request that we contact
            you through various communication channels, for example, through
            social media platforms, messenger platforms, face-to-face meetings, 
            emails and letters;
            <br />
            <br />
            (f) when you attend events or functions organized by us; 
            <br />
            <br />
            (g) we seek information about you and receive your personal data in
            connection with your relationship with us;
            <br />
            <br />
            (h) when you submit your personal data to us for any other reason; 
            <br />
            <br />
            (i) when you voluntarily complete any user survey or provide
            feedback to us via emails or any other electronic channels;
            <br />
            <br />
            (j) through automated data collection technologies upon visiting our
            Site; and
            <br />
            <br />
            (k) in other situations where we may collect your information as may
            be described in this Privacy Policy or in our terms and conditions.
          </p>
        </>

        <>
          <div className='sub-text'>
            1.4 Personal Data we receive from other sources and third parties
          </div>

          <p>
            Depending on your relationship with us, we may also collect and
            receive your personal data from third party sources, for example,
            from: 
            <br />
            <br />
            We may also receive personal data about you if you use any of the
            other websites or platforms we operate (or operated by our affiliate
            or related companies), or other services we (or our affiliate or
            related companies) provide. 
          </p>
        </>

        <div className='sub-text'>2. Processing your personal data</div>

        <>
          <div className='sub-text'>2.1 General Purposes</div>

          <p>
            We collect, use, disclose and process your personal data, including
            data provided by you, data we collect about you and data provided by
            third parties, in the following ways and upon the following grounds:
            <br />
            <br />
            (a) for the fulfilment of contractual obligations and/or
            transactions entered into between you and us, and to provide you
            with the information, or Services that you request from us;
            <br />
            <br />
            (b) facilitating the use of our Site, including verifying,
            authenticating and/or establishing your identify;
            <br />
            <br />
            (c) facilitating business asset transactions;
            <br />
            <br />
            (d) to notify you about changes to our Services;
            <br />
            <br />
            (e) to comply with any legal or regulatory obligations, applicable
            laws, regulations, codes of practices, guidelines, industry
            standards (domestic or global), government requests, or rules
            (including but not limited to Anti-Money Laundering (AML)
            regulations, Know Your Customer (KYC) rules, and Counter-Terrorist
            Financing (CTF) regulations), or to assist in law enforcement and
            investigations conducted by any governmental and/or regulatory
            authority;
            <br />
            <br />
            (f) where it is strictly necessary (i.e. to protect the vital
            interests of the users or other natural persons, to fulfil the
            purpose of public interest, or to pursue our reasonable interests);
            <br />
            <br />
            (g) security purposes, e.g. to monitor and detect suspicious
            activities, to protect users from fraudulent activities, to protect
            our Site, users' accounts, and archives from unauthorised access or
            usage, to prevent damage to our Site and systems, and to protect
            against automated abuse such as spam, and phishing;
            <br />
            <br />
            (h) communicating with you (through messages, emails, live chats or
            otherwise) and assisting you with your queries, requests, Site,
            complaints and feedback;
            <br />
            <br />
            (i) resolving any disputes or legal claims, investigating any
            complaint, claim or dispute or any actual or suspected illegal or
            unlawful conduct;
            <br />
            <br />
            (j) administrative purposes, including finance, IT and HR purposes,
            quality assurance and staff training, and compliance with internal
            policies and procedures, including audit, accounting, risk
            management and record keeping;
            <br />
            <br />
            (k) carrying out research and statistical analysis, including
            development of new products and services or evaluation, and
            improvement of our existing Services (including but not limited to,
            gaining better understanding of users' needs and behaviors,
            diagnosing any problems with our server, and quality assurance);
            <br />
            <br />
            (l) performing data analytics and related technologies on data, to
            enable us to deliver relevant content and information to you, and to
            improve our websites and digital platforms (e.g. ensuring that
            content from our Site are presented in the most effective manner for
            you and for your computer);
            <br />
            <br />
            (m) managing and engaging third parties, data processors or
            subcontractors that provide services to us, e.g. IT services,
            technological services, delivery services, and other professional
            services (e.g. accountants, lawyers and auditors);
            <br />
            <br />
            (o) any other reasonable purposes related to the aforesaid. 
            <br />
            <br />
            Subject to the terms of this Privacy Policy, we shall not use or
            process your personal data for purposes other than the purposes
            stipulated in this Privacy Policy without your prior consent. Where
            your personal data is used for a new purpose and where required
            under applicable law, we shall obtain your consent. We may collect,
            use, disclose and process your personal data for other purposes,
            without your knowledge or consent, only where this is required or
            permitted by law. 
            <br />
            <br />
            We may, if necessary or required by law, provide your personal data
            to law enforcement agencies, regulatory organisations, courts or
            other public authorities. Where appropriate, we will attempt to
            notify our customers about legal demands for their personal data.
            You agree that we may not be able to do so if such notification is
            prohibited by law or court order, when the request is an emergency,
            or when the burden or expense of notifying the customers would be
            unreasonable to us. We may dispute such demands when we believe that
            the requests are disproportionate, vague or lack proper authority,
            but we do not promise or undertake to challenge every demand.
          </p>
        </>

        <>
          <div className='sub-text'>2.2 Marketing purposes</div>

          <p>
            In order for us, or for authorized third parties permitted by us, to
            market or advertise products, events, and/or services that are of
            specific interest and relevance to you, we may analyze, use, process
            and/or rely on your personal data provided to us, or data collected
            from your interactions with us. However, no marketing or
            advertising, using your personal data in non-aggregated and/or
            identifiable form would be carried out unless you have provided us
            with your consent to use your personal data for such marketing or
            advertising purposes. If you do not want us to use or process your
            personal data for the purposes of marketing or advertising, you can
            withdraw your consent at any time by contacting us. You can exercise
            your right to prevent such use or processing by checking certain
            boxes on the forms we use to collect your personal data. If you are
            an existing customer, we will only contact you by electronic means
            (e-mail or SMS or other means) with information about goods and
            services similar to those which were the subject of a previous sale
            or negotiations of a sale to you, subject to your consent. If you
            are a new customer, and where we permit selected third parties to
            use your data for marketing or advertising purposes, we (or they)
            will contact you by electronic means only, subject to your consent. 
          </p>
        </>

        <>
          <div className='sub-text'>2.3 Legitimate business interests</div>

          <p>
            We may also collect, use, disclose and process your personal data
            for the following purposes to safeguard, support and/or carry out
            our company’s legitimate business interests such as:
            <br />
            <br />
            (a) processing of personal data for the purposes of our daily
            operations including billing and debt collecting;
            <br />
            <br />
            (b) managing our business and relationship with you (e.g. accurately
            carrying out and confirming your instructions, or for the purposes
            of providing you with rebates and other benefits), and providing
            services to our customers;
            <br />
            <br />
            (c) assistance of carrying out corporate restructuring plans;
            <br />
            <br />
            (d) protecting our rights and interests, and those of our customers
            (e.g. processing of your data for the protection of the company’s
            legal position in the event of legal proceedings);
            <br />
            <br />
            (e) processing for the purpose of reporting possible criminal acts
            (e.g. fraud) or threats to public security to competent authorities;
            <br />
            <br />
            (f) enforcing our terms and conditions, and obligations owed to us,
            or protecting ourselves from legal liability;
            <br />
            <br />
            (g) managing our investor and shareholder relations;
            <br />
            <br />
            (h) complying with internal policies, procedures, and operations,
            including troubleshooting, data analysis, testing, research,
            statistical and survey purposes; and/or
            <br />
            <br />
            (i) process or share your personal data to facilitate acquisitions,
            mergers, or transfers of our business.
          </p>
        </>

        <div className='sub-text'>3. Storing of data</div>

        <>
          <div className='sub-text'>3.1 Where we store your personal data</div>

          <p>
            The security of your personal data is our top priority. We shall
            take care in implementing and maintaining the security of our
            services and Site, as well as that of your personal data. We employ
            procedures and policies that incorporate industry best practices to
            ensure the integrity of your personal data and to prevent instances
            of unauthorized use.
            <br />
            <br />
            Please note that it is impossible to fully guarantee the security of
            your personal data. While we take reasonable steps to safeguard your
            personal data in our possession or under our control, you agree not
            to hold us liable or responsible for any loss or damage resulting
            from any unauthorized or unintended access that is beyond our
            control (e.g. hacking or cybercrimes), or abuse of your information.
            We recommend that you take independent safety precautions to protect
            your personal data, particularly your credential information such as
            your username and password. You hereby agree that we shall not be
            liable for any information leakage and other losses not caused
            intentionally or otherwise by our gross negligence, including, but
            not limited to, hacker attacks, power interruptions, or unavoidable
            technical failures. For the avoidance of doubt, we do not make any
            warranty, guarantee, or representation that your use of our systems
            or Site is safe and protected from malware, and other
            vulnerabilities. We also do not guarantee the security of data that
            you choose to send us electronically. Sending such data is entirely
            at your own risk. 
          </p>
        </>

        <>
          <div className='sub-text'>
            3.2 For how long will your data be stored?
          </div>

          <p>
            We will process and store your personal data for as long as it is
            necessary in order to fulfill the purposes we collected it for, and
            to satisfy our business, contractual, legal, regulatory and/or
            statutory obligations (including audit, accounting or reporting
            purposes). We may thus be required to retain certain information,
            including personal data or information of users, users' profiles,
            identification verification materials, information relevant to
            AML/KYC/CTF procedures, account information, account agreements, and
            other agreements between us and third parties, account statements,
            and other records, for an extended period of time.
            <br />
            <br />
            We will assess and respond to requests to delete personal data and
            we shall accordingly delete personal data provided that the personal
            data is no longer required in order to fulfill our business,
            contractual, legal, regulatory and/or statutory obligations, or the
            fulfillment of any obligations to preserve records according to
            applicable laws and regulations. 
            <br />
            <br />
            In general, how long we keep your personal data depends on the
            nature of the data, e.g. we keep personal data for at least the
            duration of the limitation period for bringing claims if the
            personal data may be required to commence or defend legal
            proceedings. Some information may also be retained for longer e.g.
            where we are required to do so by law.
          </p>
        </>

        <>
          <div className='sub-text'>3.3 Anonymized data</div>
          <p>
            In some circumstances we may anonymize and/or aggregate your
            personal data so that it can no longer be associated with you, in
            which case we are entitled to retain and use such data without
            restriction.
          </p>
        </>

        <div className='sub-text'>4. Disclosure of your personal data </div>

        <p>
          Your personal data may be made available, disclosed or shared to our
          related parties and/or within our group of companies in order to
          provide our Services or Site to you, for management and compliance
          purposes, and to utilize shared group IT functions.
          <br />
          <br />
          We may share, transfer, disclose, or allow access to your personal
          data to third parties in connection with the purposes described in
          Clause 2, including without limitation the purposes described below:
          <br />
          <br />
          (a) to administer or process a transaction, or services you have
          authorized or requested, or in the context of facilitating the
          execution of a transaction;
          <br />
          <br />
          (b) to facilitate or otherwise enable the sale of Rover Seals NFTs (or
          other products) or any other activity on the Site which you choose to
          participate in, including the provision of your personal data to the
          third party entities to comply with applicable legal, regulatory,
          compliance or statutory requirements (e.g. AML/KYC/CTF procedures);
          <br />
          <br />
          (c) to carry out or aid in certain functions during the account
          opening, updating and/or maintenance process, such as, but not limited
          to, account processing, surveillance, reconciliation, execution,
          document retention requirements, and document dissemination;
          <br />
          <br />
          (d) to process payments which you have authorized (e.g. disclosure to
          financial institutions that we have partnered with);
          <br />
          <br />
          (e) to operate and/or improve our Services;
          <br />
          <br />
          (f) if we are discussing selling or transferring part or all of our
          business, to a purchaser of our business. The information may be
          transferred to prospective purchasers under suitable terms as to
          confidentiality;
          <br />
          <br />
          (g) if we are reorganised or sold, information may be transferred to a
          buyer who can continue to provide the Site and related services to
          you;
          <br />
          <br />
          (h) to facilitate account closing where you have a deficient balance,
          or upon excessive instances where you do not have sufficient funds in
          your account;
          <br />
          <br />
          (i) to third parties that provide services to us, such as, but not
          limited to, data providers, technology providers, consulting, sales,
          client support operations, payment processing, authentication
          services, professional services (including accountants, lawyers and
          auditors), and technical support or services;
          <br />
          <br />
          (j) to third parties in order to fulfil such third party products
          and/or services as may be requested or directed by you;
          <br />
          <br />
          (k) if we are defending a legal claim your information may be
          transferred as required in connection with defending such claim;
          <br />
          <br />
          (l) to law enforcement agencies, authorities, public agencies and
          government officials, or other third parties when we are compelled to
          do so by a subpoena, court order, or similar legal procedure, or when
          we believe in good faith that the disclosure of personal data is
          necessary to prevent physical harm or financial loss, to report
          suspected illegal activity or to investigate violations of any of our
          terms and conditions, or any other applicable policies; and 
          <br />
          <br />
          (m) other circumstances under which we may disclose your personal data
          such as:
          <br />
          <br />
          i. to comply, as necessary, with applicable laws and regulatory
          requirements;
          <br />
          <br />
          ii. respond to mandatory legal or governmental requests or demands for
          information;
          <br />
          <br />
          iii. meet national security requirements;
          <br />
          <br />
          iv. enforce our agreements, policies, procedures and/or Terms of
          Service;
          <br />
          <br />
          v. protect ourselves, our affiliates, our users, our counterparties or
          the general public from illegal activities; and
          <br />
          <br />
          vi. to respond to an emergency that requires disclosure of your
          personal data.  You may freely opt-out of having your personal data
          shared with third parties (or for any of the above listed purposes),
          or from allowing us to use your personal data for any purpose that is
          incompatible with the purposes for which we originally collected or
          subsequently obtained your authorization as stipulated in this Privacy
          Policy. Please contact us if you wish to opt-out. 
          <br />
          <br />
          We will ensure that all companies and/or third parties to whom we
          disclose your personal data will only process it in accordance with
          our instructions and on our behalf. We will, where appropriate and
          permissible, enter into contracts with these third parties to protect
          your personal data in a manner that is consistent with all applicable
          laws. All such companies and third parties will be required by us to
          meet the requirements of applicable data protection legislation and
          our strict privacy and retention policies to keep your personal data
          secure at all times.
        </p>

        <>
          <div className='sub-text'>
            5. Transfer of Personal Data to other countries
          </div>
          <p>
            You agree and acknowledge that we may transfer your personal data to
            different jurisdictions in connection with the purposes described in
            this Privacy Policy: 
            <br />
            <br />
            (a) from the jurisdiction where it is collected (or where you are
            located) to any other jurisdictions that we operate in; and
            <br />
            <br />
            (b) to third parties in other jurisdictions. 
            <br />
            <br />
            When we transfer your personal data internationally and where
            required by applicable law, we will put in place appropriate
            safeguards. You may obtain details of these safeguards by contacting
            us.
          </p>
        </>

        <>
          <div className='sub-text'>
            6. Third-Party Collection of Personal Information
          </div>

          <p>
            Our Site may contain links to other websites or platforms that are
            not owned or maintained by us. These links are provided only for
            your convenience. You may also be accessing our Site through third
            party websites and/or platforms. This Privacy Policy only applies to
            our Site. When visiting third party websites or using their
            platforms, their privacy policies apply to their collection, use or
            disclosure of the personal data you disclose to them.
            <br />
            <br />
            You hereby acknowledge that we shall not be responsible for the
            products, services, or descriptions of products or services that you
            receive from these third-party websites or platforms, or to the
            content or privacy practices of those websites, or platforms. This
            Privacy Policy shall not be applied to any such third-party products
            and services that you access through our Site. You knowingly and
            voluntarily assume all risks of using such third-party websites or
            platforms to purchase products and services, and you agree that we
            shall have no liability whatsoever concerning such third-party
            websites or platforms and your usage of them.
          </p>
        </>

        <>
          <div className='sub-text'>7. Your rights</div>

          <p>
            Depending on the jurisdiction that you are in or where we operate,
            you may enjoy certain rights under applicable law in relation to our
            collection, use, disclosure and processing of your personal data.
            Such rights may include:
            <br />
            <br />
            (a) <span className='text-hightlight'>Access:</span> you may ask us
            if we hold your personal data and, if we are, you can request access
            to your personal data free of charge. This enables you to receive a
            copy of and information on the personal data we hold about you;
            <br />
            <br />
            (b) <span className='text-hight'>Correction:</span> you may request
            for the rectification or correction of your personal data that is
            incomplete or inaccurate;
            <br />
            <br />
            (c) <span className='text-hightlight'>Restriction:</span> you may
            withdraw consent for our use or process of your personal data, or
            ask us to suspend the process of your personal data (e.g. if you
            want us to establish its accuracy);
            <br />
            <br />
            (d) <span className='text-hightlight'>Objection:</span> where we are
            processing your personal data based on a legitimate interest (or
            those of a third party), you may object to processing on this
            ground;
            <br />
            <br />
            (e) <span className='text-hightlight'>Portability:</span> you may
            request for the transfer of your personal data to another party
            under certain circumstances; and
            <br />
            <br />
            (f) <span className='text-hight'>Erasure:</span> you may request the
            erasure, deletion or removal of your personal data that we hold
            about you in certain circumstances.
            <br />
            <br />
            If you wish to exercise any of your rights, you may contact us at{' '}
            <a href='mailto:legal@finwallet.com' className='text-hightlight'>
              legal@finwallet.com
            </a>
            . We may require that you submit certain forms or provide certain
            information to process your request. Where permitted by law, we may
            also charge you a fee to process your request.
            <br />
            <br />
            We may be permitted under applicable laws to refuse a request, for
            example, we may refuse (a) a request for erasure where the personal
            data is required for in connection with claims; or (b) an objection
            request and continue processing your personal data based on
            compelling legitimate grounds for the processing.
          </p>
        </>
      </div>
    </LayoutText>
  )
}

export default PrivacyPolicy
