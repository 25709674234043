import React from 'react'

import styles from './Terms.module.scss'
import LayoutText from 'pages/layouts/LayoutText'

function Terms() {
  return (
    <LayoutText title='TERMS OF USE' date='August, 2023'>
      <div className={styles.termWrapper}>
        <p>
          FinWhale Labs, LLC (doing business as{' '}
          <span className='text-hightlight'>“Rover Seals”</span>) celebrates
          representation, inclusivity and equal opportunities for all, through
          its community, Web3 initiatives and collections of non-fungible tokens
          (<span className='text-hightlight'>“NFT”</span>) based on the SEI
          Blockchain to which are attached visual assets representing unique,
          waddling and diverse seals (
          <span className='text-hightlight'>“Rover Seals NFT”</span>).
          <br />
          <br />
          These Terms of Use (<span className='text-hightlight'>“Terms”</span>)
          apply to your purchase, sale, display, and use of Rover Seals NFT and
          the use of this site [
          <span className='text-hightlight'>http://roverseals.com/</span>] (the{' '}
          <span className='text-hightlight'>“Site”</span>,{' '}
          <span className='text-hightlight'>“Rover Seals Website”</span>) of the
          person who gets access to, and/or use this Site (
          <span className='text-hightlight'>
            “users”, “user”, “you”, “your”
          </span>
          ). These Terms are entered into between you and Rover Seals (
          <span className='text-hightlight'>
            “Rover Seals”, “our”, “we”, “us”
          </span>
          ). These Terms expressly incorporate any other documents referenced
          herein, including but not limited to Rover Seals NFT License Agreement
          and our Privacy Policy, govern your purchase, sale, display, and use
          of Rover Seals NFT and the use of this Site as well as all content,
          functionality, and Services offered on or through the Site, including
          Rover Seals NFT.
          <br />
          <br />
          PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO
          ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
          REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH
          LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO
          BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE
          PERMITTED TO PURSUE CLAIMS AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A
          PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
          PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING
          MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS,
          AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US
          RESOLVED BY A JURY OR IN A COURT OF LAW. FOR FURTHER INFORMATION, SEE
          “DISPUTE RESOLUTION” BELOW.
          <br />
          <br />
          These Terms constitute the entire agreement between you and us and
          govern your purchase, sale, display, and use of Rover Seals NFT and
          the use of this Site, superseding any prior agreements between you and
          us with respect to Rover Seals Website. You also may be subject to
          additional terms and conditions that may apply when you use
          third-party services, Third-Party Content (as defined in Section 9),
          or third-party software. 
        </p>

        <>
          <div className='sub-text'>
            1. CONDITIONS OF USE OF THE ROVER SEALS WEBSITE
          </div>

          <p>
            Neither Rover Seals Website, nor any Rover Seals NFT is intended for
            use by any person or entity in any jurisdiction or country where
            such use would be contrary to law or regulation or which would
            subject us to any registration requirement within such jurisdiction
            or country.
            <br />
            <br />
            The Rover Seals Website is intended for users who are at least
            eighteen (18) years old. Thus, by accessing and using the Rover
            Seals Website, you confirm that you are at least eighteen (18) years
            old and you are deemed to have read and accepted these Terms
            exhaustively and unconditionally.
            <br />
            <br />
            In case of your disagreement, in whole or in part, with these Terms,
            you must immediately cease any and all use of the Rover Seals
            Website.
          </p>
        </>

        <>
          <div className='sub-text'>2. ACCESS TO THE ROVER SEALS WEBSITE</div>
          <p>
            You are personally responsible for setting up the IT and
            telecommunications resources required to access the Rover Seals
            Website and use the Services. You shall bear the cost of
            telecommunications when accessing the internet to use the Rover
            Seals Website.
            <br />
            <br />
            Rover Seals reserves the right to, without notice nor compensation,
            temporarily or permanently, close the Rover Seals Website or access
            to one or more Services in order to perform maintenance operations.
            Rover Seals may carry out any changes and improvements to the Rover
            Seals Website and Services that it deems necessary. In the event you
            do not agree to such changes and improvements, you shall cease any
            and all utilization of the Rover Seals Website and Services.
          </p>
        </>

        <div className='sub-text'>3. SERVICES</div>

        <>
          <div className='sub-text'>3.1 GENERAL SERVICES</div>
          <p>
            (a) Rover Seals Website gives you access to functionalities,
            features, and services related to Rover Seals NFT collections and
            community (the <span className='text-hightlight'>“Services”</span>
            ). 
            <br />
            <br />
            Rover Seals NFT are associated with artwork, graphics, images,
            designs, logos, drawings, photographs, text, taglines, or video or
            audio recordings and all intellectual property rights in the
            foregoing (collectively,{' '}
            <span className='text-hightlight'>“Metadata”</span>). Rover Seals
            NFT and Metadata associated with Rover Seals NFT available on Rover
            Seals Website are created by FinWhale Labs, LLC (
            <span className='text-hightlight'>“Creator”</span>). Rover Seals NFT
            may be listed for sale on Rover Seals Website. Rover Seals NFT are
            generated through smart contracts created and initially recorded by
            the Creator on SEI Blockchain (the{' '}
            <span className='text-hightlight'>“Relevant Network”</span>).
            Relevant Networks utilize open-source code and are operated by a
            group of independent node operators (
            <span className='text-hightlight'>“Node Operators”</span>).
            <br />
            <br />
            Rover Seals NFT are owned by controlling a private key associated
            with a public address on the Relevant Network and will continue to
            exist as long as the Node Operators from time to time maintain the
            Relevant Network (or the Rover Seals NFT has been recorded on
            another blockchain network).  This means that all Rover Seals NFT
            are outside of the control of any one party, including Rover Seals,
            and any single Node Operator. Rover Seals NFT are nevertheless
            subject to many risks and uncertainties. In addition, the Metadata,
            which is the related visual, audio, or other work associated with a
            Rover Seals NFT, may or may not be separately maintained by the
            Creator. Unless expressly indicated on these Terms, ownership of a
            Rover Seals NFT will not entitle you to possession of, or any rights
            in, any physical artwork or other physical items, even if a physical
            artwork or other items from which the Metadata was derived exists.
            <br />
            <br />
            (b) Access to some Services may only be available to the holder of a
            Rover Seals NFT and require proof of ownership by connecting a
            supported blockchain-based digital wallet to the Rover Seals Website
            (<span className='text-hightlight'>“Digital Wallet”</span>).
            <br />
            <br />
            Digital Wallet are third-party products and services. Access and use
            of Digital Wallet are at the user’s own risk and discretion and are
            subject to their own applicable terms. You are solely responsible
            for your Digital Wallet security, including associated passwords,
            seed words, and keys. Rover Seals shall not, in any event
            whatsoever, be held responsible for any risk associated with your
            access and/or use of a Digital Wallet. In the event you lose or can
            no longer access your Digital Wallet, Rover Seals cannot retrieve
            your Rover Seals NFT from your Digital Wallet.
            <br />
            <br />
            When you link your Digital Wallet, you understand and agree that you
            are solely responsible for maintaining the security of your Digital
            Wallet and your control over any wallet-related authentication
            credentials, private or public cryptocurrency keys, non-fungible
            tokens or cryptocurrencies that are stored in or are accessible
            through your Digital Wallet. Any unauthorized access to your Digital
            Wallet by third parties could result in the loss or theft of Rover
            Seals NFT and/or funds held in your Digital Wallet and any
            associated wallets, including any linked financial information such
            as bank account(s) or credit card(s). We are not responsible for
            managing and maintaining the security of your Digital Wallet nor for
            any unauthorized access to or use of your Digital Wallet. If you
            notice any unauthorized or suspicious activity in your Digital
            Wallet that seems to be related to this Site, please notify us
            immediately.
          </p>
        </>

        <div className='sub-text'>4. UNDERTAKINGS OF THE PARTIES</div>

        <>
          <div className='sub-text'>4.1 USERS UNDERTAKINGS</div>
          <p>
            Users shall not, directly or indirectly, without being expressly
            authorized by these Terms and/or the digital ownership assignment,
            or by prior written consent of Rover Seals, in any way whatsoever:
            <br />
            <br />
            (i) Copy, modify, communicate, transmit or create any derivative
            work, use, commercialize or reproduce all or part of the Rover Seals
            Website and/or Rover Seals property, and related intellectual
            property rights of Rover Seals, or register or attempt to register
            any trademark or copyright or otherwise acquire additional
            intellectual property rights in or to any Rover Seals property; use
            the Rover Seals Website or any Rover Seals property to create,
            endorse, support, promote or condone any content, material or speech
            that is, or may be deemed to be by Rover Seals at its sole
            discretion, defamatory, obscene, pornographic, indecent, abusive,
            offensive, harassing, violent, hateful, racist, discriminatory,
            inflammatory or otherwise objectionable or inappropriate; and, in
            general, access, download, upload, share, communicate any content
            and/or use the Rover Seals Website or Rover Seals property in a way
            that may infringe the rights, including intellectual property
            rights, of Rover Seals or third parties, damage the reputation,
            rights and interests of Rover Seals, or falsely suggest an
            affiliation with or endorsement by Rover Seals.
            <br />
            <br />
            (ii) Decompile, disassemble the Rover Seals Website, reverse
            engineer or attempt to discover or reconstruct the source code, the
            ideas on which it is based, the algorithms, the file formats, or the
            programming or interoperability interfaces of the Rover Seals
            Website except to the extent permitted by law, in any manner
            whatsoever. In the event the user wishes to obtain the information
            necessary to implement the interoperability of the Rover Seals
            Website with other software developed or independently acquired by
            the user, for use in accordance with the destination of the Rover
            Seals Website, the user undertakes, before relying on a third party,
            to first consult Rover Seals which may provide the necessary
            information for the implementation of such interoperability. The
            user acknowledges that, where appropriate, the exact cost incurred
            internally by Rover Seals for providing this information will be
            invoiced by Rover Seals to the user;
            <br />
            <br />
            (iii) Proceed alone, or with the help of a third-party service
            provider, to correct any errors in and/or alter any content of the
            Rover Seals Website in order to make it conform to her/his own
            purposes (Rover Seals alone reserves for itself the exercise of this
            right in accordance with exceptions provided by law); or integrate
            or combine the Rover Seals Website with other software or documents
            or create composite or derivative works with help of all or parts of
            the Rover Seals Website; and, in general, violate any law or
            regulation, or make any use of the Rover Seals Website, Services or
            Rover Seals property that may be deemed contrary to public order or
            unfair.
          </p>
        </>

        <>
          <div className='sub-text'>4.2 UNDERTAKINGS OF ROVER SEALS</div>
          <p>
            Other than our obligations set out in these Terms, you agree that
            we:
            <br />
            <br />
            <ul>
              <li>
                have not made, and do not make any representation, guarantee, or
                warranty that any Rover Seals NFT will meet your requirements or
                expectations;
              </li>
              <br />
              <li>
                are not liable or responsible for any increase or decrease in
                value of any Rover Seals NFT;
              </li>
              <br />
              <li>
                have not made, and do not make, any promises (whether explicit,
                implied or otherwise) as to the value of any Rover Seals NFT,
                including whether the value of the Rover Seals NFT will increase
                or decrease in the future; and
              </li>
              <br />
              <li>
                will not be responsible for the negligence, fraud or misconduct
                of you or other users.
              </li>
               
            </ul>
            Rover Seals endeavors use reasonable efforts to (i) act honestly,
            fairly, and professionally, (ii) communicate with the holders of
            Rover Seals NFT in a fair and clear manner, (iii) prevent, identify,
            and manage any conflicts of interest that may arise, (iv) maintain
            all of its systems and security access protocols to appropriate
            standards of applicable laws and regulations, and, in general (v)
            act in the best interests of the holders of Rover Seals NFT and
            treat them equally unless provided otherwise on the Rover Seals
            Website or any other notice by Rover Seals.
          </p>
        </>

        <div className='sub-text'>5. SECURITY</div>

        <>
          <div className='sub-text'>5.1 ROVER SEALS WEBSITE SECURITY</div>
          <p>
            Rover Seals shall make its best efforts to deploy the necessary
            resources to ensure users’ access to and effective operation of the
            Rover Seals Website. However, given the limitations related to the
            internet, Rover Seals cannot guarantee that access and operation of
            the Rover Seals Website will not be interrupted, such as in the
            event of force majeure, malfunction of the users’ equipment,
            malfunctions of the users’ internet networks and/or maintenance
            operations designed to improve the Rover Seals Website.
            <br />
            <br />
            The user hereby represents, warrants, and agrees that, when
            accessing and/or using the Rover Seals Website and Services, the
            user shall refrain from any act and/or omission which could, in any
            way whatsoever (i) jeopardize the security of the Rover Seals
            Website; (ii) attempt, in any manner, to obtain passwords or other
            security information from any other user; (iii) violate the security
            of any computer network, or cracks any passwords or security
            encryption codes; (iv) run any processes that interfere with the
            proper working of the Rover Seals Website and Services; (v) disturb,
            hinder and/or prevent the proper functioning of the Rover Seals
            Website and Services (such as, but not limited to,  downloading
            viruses or malicious code whatsoever or by performing hacking
            activities of any nature or that otherwise interfere with the proper
            functioning of the Rover Seals Website and Services).
            <br />
            <br />
            While we attempt to make your access to and use of the Site and Site
            Content safe, we cannot and do not represent or warrant that the
            Site, Site Content, any Rover Seals NFT listed on our Site, or our
            servers are free of viruses or other harmful components. We cannot
            guarantee the security of any data that you disclose online. You
            accept the inherent security risks of providing information and
            dealing online over the internet and will not hold us responsible
            for any breach of security unless it is due to our gross negligence.
          </p>
        </>

        <>
          <div className='sub-text'>5.2 MALICIOUS THIRD PARTIES</div>
          <p>
            Please be aware that malicious third-parties may offer for sale NFT
            counterfeited Rover Seals property and/or falsely act as affiliated
            to or endorsed by Rover Seals. 
            <br />
            <br />
            Your interactions with these third parties are at your own risks and
            discretion. Rover Seals is not responsible, in any way whatsoever,
            for any act or omission of said malicious third parties, nor any
            adverse consequence resulting from your interactions with them.
          </p>
        </>

        <div className='sub-text'>6. DISCLAIMER</div>

        <>
          <div className='sub-text'>6.1 THE SCOPE OF SERVICES</div>
          <p>
            Rover Seals offers Services related to NFT that are unique and not
            fungible with other crypto-assets. Each Rover Seals NFT is unique
            and exclusive to its owner.
            <br />
            <br />
            Rover Seals is not a provider of digital asset services and/or
            virtual asset services including, but not limited to, services
            regarding initial coin offering; custody of digital assets on behalf
            of third parties, or access to digital assets for the purpose of
            holding, storing, and transferring digital assets; the purchase and
            sale of digital assets in legal tender; the exchange of digital
            assets for other digital assets; the operation of a digital asset
            trading platform; or any services related to third party order
            reception and transmission, third party portfolio management,
            advice, underwriting, guaranteed placement, and unguaranteed
            placement.
            <br />
            <br />
            Rover Seals does not provide asset intermediation services
            including, but not limited to, services related to the offer, to
            clients or potential clients, to acquire rights on one or more
            assets on the basis of the possibility of a direct or indirect
            financial return or similar economic effect.
            <br />
            <br />
            In this regard, the user hereby represents warrants and agrees that
            any use of the Rover Seals Website and Services, including any
            purchase of a Rover Seals NFT, is made for its sole personal
            collection, use, and enjoyment. User shall refrain from any act
            and/or omission which could, in any way whatsoever, be interpreted
            as or suggest that (i) the user is accessing or using the Rover
            Seals Website and/or Services, including purchasing a Rover Seals
            NFT, for speculative or investment purposes, for use as a substitute
            for currency or other medium of exchange, or for Resale or
            redistribution, or that (ii) accessing or using the Rover Seals
            Website and/or Services, including purchasing a Rover Seals NFT, may
            constitute an opportunity to gain economic benefit or profit, or an
            investment, equity, or other ownership or profit-sharing interest in
            Rover Seals or its Affiliates or business partners of any kind. In
            this regard, Rover Seals makes no warranties whatsoever with regard
            to any future value or Resale price of a Rover Seals NFT.
          </p>
        </>

        <>
          <div className='sub-text'>6.2 THE USE OF SERVICES</div>
          <p>
            ROVER SEALS WEBSITE, ROVER SEALS NFT AND ANY SERVICES ARE PROVIDED
            ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. YOU AGREE THAT YOUR USE OF
            ROVER SEALS WEBSITE, ANY ROVER SEALS NFT AND OUR SERVICES WILL BE AT
            YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
            ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH ROVER SEALS
            WEBSITE, ROVER SEALS NFT, THE METADATA, THE SERVICES AND YOUR USE
            THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE SHALL NOT BE LIABLE FOR ANY FAILURE TO PERFORM
            ANY OBLIGATIONS UNDER THESE TERMS TO THE EXTENT CAUSED BY AN EVENT
            THAT IS BEYOND OUR REASONABLE CONTROL.
            <br />
            <br />
            WE MAKE NO WARRANTY THAT (I) ROVER SEALS WEBSITE, ANY ROVER SEALS
            NFT, ANY METADATA OR ANY SERVICES WILL MEET YOUR REQUIREMENTS, (II)
            ROVER SEALS WEBSITE OR USE OF ANY ROVER SEALS NFT, ANY METADATA OR
            THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
            (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE ROVER
            SEALS WEBSITE, ANY ROVER SEALS NFT, ANY METADATA OR ANY SERVICES
            WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY ROVER SEALS
            NFT, ANY METADATA, ANY SERVICES OR ANY PRODUCTS, INFORMATION, OR
            OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH ROVER SEALS
            WEBSITE WILL MEET YOUR EXPECTATIONS.
            <br />
            <br />
            WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
            COMPLETENESS OF SITE CONTENT OR THE CONTENT OF ANY WEBSITES LINKED
            TO ROVER SEALS WEBSITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
            ROVER SEALS WEBSITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
            SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
            FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
            CESSATION OF TRANSMISSION TO OR FROM ROVER SEALS WEBSITE, (5) ANY
            BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED
            TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
            OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
            ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
            TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA ROVER SEALS WEBSITE. WE
            DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
            PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
            ROVER SEALS WEBSITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
            MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND
            WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
            MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS
            OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
            SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
            YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. 
            <br />
            <br />
            YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE USE OF THE SITE, THE 
            SERVICES,  AND THE CONTENT OF THE ROVER SEALS WEBSITE IS AT YOUR
            SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY,
            PERFORMANCE, ACCURACY, AND EFFORT IS WITH YOU.
          </p>
        </>

        <>
          <div className='sub-text'>6.3 LIABILITIES</div>
          <p>
            You are users of Rover Seals Website, not deemed to be the consumer
            of any service provided in and/or from within Rover Seals Website.
            You are the sole responsible for your compliance with applicable
            laws and regulations, including tax obligations in relation to NFT
            and cryptocurrencies. Please be aware that changes in applicable
            laws and regulations may impact the Services, your rights and
            obligations regarding Rover Seals NFT you hold.
            <br />
            <br />
            We will not be responsible or liable to you for any loss and take no
            responsibility for, and will not be liable to you for, any use of
            the Site including but not limited to any losses, damages, or claims
            arising from: (a) user error such as forgotten passwords,
            incorrectly constructed transactions, or mistyped addresses; (b)
            server failure or data loss; (c) corrupted wallet files; (d)
            unauthorized access to applications; (e) any unauthorized third
            party activities, including without limitation the use of viruses,
            phishing, brute forcing or other means of attack against Rover Seals
            Website or the Services.
          </p>
        </>

        <>
          <div className='sub-text'>6.4 PERSONAL DATA COLLECTION</div>
          <p>
            We do not collect or store any personal data. We only use cookies
            that are essential to run this Rover Seals Website. The Site may
            contain or reference links to websites operated by third parties.
            These links are provided as a convenience only. We are not
            responsible for the obligation related to the data protection,
            content, or other activities of any Third Party Website (as defined
            in Section 9) or any link contained in a Third Party Website. We do
            not review, approve, monitor, endorse, warrant, or make any
            representations with respect to third-party websites, and the
            inclusion of any link in the service, offers, or any other services
            provided in connection with them. We are not responsible for any
            liabilities, and expressly disclaim any liability for them.
          </p>
        </>

        <>
          <div className='sub-text'>6.5 RISK ASSUMPTION</div>
          <p>
            You accept and acknowledge each of the following:
            <br />
            <br />
            To the extent that you sell your Rover Seals NFT, please be aware
            that the prices of NFTs are extremely volatile and fluctuations in
            the prices of other NFTs and impact the price of your Rover Seals
            NFT both positively and negatively.  Given the volatility, NFTs such
            as Rover Seals NFT should not be considered an investment. You
            assume all risks in that connection.
            <br />
            <br />
            No information on this Site (or any other documents mentioned
            therein) is or may be considered to be advice or an invitation to
            enter into an agreement for any investment purpose. Further, nothing
            on this Site qualifies or is intended to be an offering of
            securities in any jurisdiction nor does it constitute an offer or an
            invitation to purchase shares, securities or other financial
            products.  Due to the artistic nature of the project, Rover Seals
            NFT may not been registered with or approved by any regulator in any
            jurisdiction. It remains your sole responsibility to assure that the
            purchase of Rover Seals NFT and the associated art is in compliance
            with laws and regulations in your jurisdiction.
            <br />
            <br />
            NFTs, cryptocurrencies and blockchain technology are relatively new
            and the regulatory landscape is unsettled.  New regulations could
            negatively impact such technologies impacting the value for your
            Rover Seals NFT. You understand and accept all risk in that regard.
          </p>
        </>

        <>
          <div className='sub-text'>7. INDEMNIFICATION</div>
          <p>
            To the fullest extent permitted by applicable law, you will
            indemnify, defend and hold harmless Rover Seals, its Affiliates, and
            its officers, directors, agents, partners and employees
            (individually and collectively, the “Rover Seals Parties”) from and
            against all actual or alleged claims, damages, awards, judgments,
            losses, liabilities, obligations, taxes, penalties, interest, fees,
            expenses (including, without limitation, attorneys’ fees and
            expenses), and costs (including, without limitation, court costs,
            costs of settlement, and costs of pursuing indemnification and
            insurance), of every kind and nature whatsoever, whether known or
            unknown, foreseen or unforeseen, matured or unmatured, or suspected
            or unsuspected, in law or equity, whether in tort, contract, or
            otherwise (collectively, “Claims”) arising out of or related to (a)
            your use of misuse of the Site, Services, any Rover Seals NFT or any
            Metadata; (b) your violation, misappropriation or infringement of
            any rights of another (including intellectual property rights or
            privacy rights), (c) your violation or breach of any term of these
            Terms or applicable law, (d) your violation of the rights of or
            obligations to a third party, including another user or third party,
            and (e) your negligence, fraud or willful misconduct. You agree to
            promptly notify Rover Seals Parties of any third-party Claims,
            cooperate with Rover Seals Parties in defending such Claims and pay
            all fees, costs and expenses associated with defending such Claims
            (including attorneys’ fees). You also agree that the Rover Seals
            Parties will have control of the defense or settlement, at Rover
            Seals’ sole option, of any third-party Claims. This indemnity is in
            addition to, and not in lieu of, any other indemnities set forth in
            a written agreement between you and Rover Seals. 
            <br />
            <br />
            If Rover Seals is obligated to respond to a subpoena or other
            compulsory legal or court order or process relating to subject
            matter that is within the scope of your indemnity under this
            Section, you agree to reimburse Rover Seals its legal fees, as well
            as its employees’ and contractors’ time and materials spent
            responding to the subpoena or other compulsory legal or court order
            or process at reasonable hourly rates.
          </p>
        </>
        <>
          <div className='sub-text'>8. LIMITATION OF LIABILITY</div>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL ROVER SEALS
            BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY
            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
            DAMAGES ARISING FROM THESE TERMS, ROVER SEALS WEBSITE, SITE CONTENT,
            THE SERVICES OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES
            RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
            ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA,
            WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
            OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF ROVER SEALS HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF,
            THE SITE, SITE CONTENT, THE SERVICES, OR THIRD-PARTY WEBSITES AND
            PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
            RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE
            OR LOSS OF DATA RESULTING THEREFROM.
            <br />
            <br />
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
            EVENT SHALL THE MAXIMUM TOTAL AGGREGATE LIABILITY OF ROVER SEALS AND
            ROVER SEALS PARTIES ARISING OUT OF, UNDER OR IN CONNECTION WITH
            THESE TERMS, THE SERVICES OR OUR BUSINESS RELATIONSHIP EXCEED FIVE
            HUNDRED DOLLARS (US$500).
            <br />
            <br />
            CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
            OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
            APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
            MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. IF YOU ARE
            DISSATISFIED WITH ANY PORTION OF THE SITE OR WITH THESE TERMS, YOUR
            SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SITE.
          </p>
        </>

        <>
          <div className='sub-text'>9. THIRD-PARTY WEBSITE AND CONTENT</div>
          <p>
            Rover Seals Website may contain (or you may be sent via Rover Seals
            Website) links to other websites (
            <span className='text-hightlight'>“Third-Party Websites”</span>) as
            well as articles, photographs, text, graphics, pictures, designs,
            music, sound, video, information, applications, software, and other
            content or items belonging to or originating from third parties (
            <span className='text-hightlight'>“Third-Party Content”</span>).
            Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Site or any Third-Party
            Content posted on, available through, or installed from the Site,
            including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Site and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms no longer govern. You should review the applicable terms and
            policies, including privacy and data gathering practices, of any
            website to which you navigate from the Site or relating to any
            applications you use or install from the Site. Any purchases you
            make through Third-Party Websites will be through other websites and
            from other companies, and we take no responsibility whatsoever in
            relation to such purchases which are exclusively between you and the
            applicable third party. You agree and acknowledge that we do not
            endorse the products or services offered on Third-Party Websites and
            you shall hold us harmless from any harm caused by your purchase of
            such products or services. Additionally, you shall hold us harmless
            from any losses sustained by you or harm caused to you relating to
            or resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </p>
        </>
        <>
          <div className='sub-text'>10. SITE MANAGEMENT</div>
          <p>
            We reserve the right, but not the obligation, to: (1) monitor Rover
            Seals Website for violations of these Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Terms, including without limitation, reporting such
            user to law enforcement authorities; (3) in our sole discretion and
            without limitation, notice, or liability, to remove from Rover Seals
            Website or otherwise disable all files and content that are
            excessive in size or are in any way burdensome to our systems; and
            (4) otherwise manage Rover Seals Website in a manner designed to
            protect our rights and property and to facilitate the proper
            functioning of Rover Seals Website.
          </p>
        </>
        <>
          <div className='sub-text'>11. MODIFICATIONS AND INTERRUPTIONS</div>
          <p>
            We reserve the right to change, modify, or remove the Site Content
            at any time or for any reason at our sole discretion without notice.
            However, we have no obligation to update any information on our
            Site. We also reserve the right to modify or discontinue all or part
            of the Site without notice at any time. We will not be liable to you
            or any third party for any modification, price change, suspension,
            or discontinuance of the Site.
            <br />
            <br />
            We cannot guarantee the Site will be available at all times. We may
            experience hardware, software, or other problems or need to perform
            maintenance related to the Site, resulting in interruptions, delays,
            or errors. We reserve the right to change, revise, update, suspend,
            discontinue, or otherwise modify the Site at any time or for any
            reason without notice to you. You agree that We have no liability
            whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these Terms will be construed
            to obligate us to maintain and support the Site or to supply any
            corrections, updates, or releases in connection therewith.
            <br />
            <br />
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.
          </p>
        </>

        <div className='sub-text'>12. MISCELLANEOUS</div>

        <>
          <div className='sub-text'>12.1. BREACH OF TERMS</div>
          <p>
            If the user’s utilization of the Rover Seals Website is deemed in
            breach of the Terms, Rover Seals reserves the right to limit,
            suspend or terminate, temporarily or permanently, as of right,
            without any indemnification, compensation, or refund whatsoever
            being owed and without any prejudice to any legal action that Rover
            Seals may be entitled to, the user’s access to the Rover Seals
            Website and/or the Services available to the user. Rover Seals shall
            inform the user of its decision to suspend or limit the user’s
            rights and may, at its discretion, decide to restore the user’s
            rights.
          </p>
        </>
        <>
          <div className='sub-text'>12.2. THIRD-PARTY CONTENT</div>
          <p>
            The Rover Seals Website may display hypertext links redirecting the
            user to Third-Party Websites and/or applications. Rover Seals shall
            not be held liable, in any way whatsoever, in respect of any
            hypertext links to third parties that may be accessible on the Rover
            Seals Website. In this respect, please note that Rover Seals neither
            has any control over the content published by such third parties nor
            monitors such content. As a result, Rover Seals shall in no event be
            held liable in respect of any content published by third parties
            whatsoever. The user is hereby invited to duly read the terms and
            conditions governing Third-Party Websites and applications that the
            user may access by clicking on hypertext links displayed on the
            Rover Seals Website and related websites.
            <br />
            <br />
            In the event that any content posted on the Rover Seals Website does
            not comply with these Terms and/or infringes applicable legal and
            regulatory provisions, in particular, because it constitutes
            manifestly unlawful content (e.g., defamatory, denigrating, or
            infringing on the user's intellectual property rights), any user may
            report such infringement by contacting Rover Seals in accordance
            with Section 14.
          </p>
        </>
        <>
          <div className='sub-text'>12.3. ASSIGNMENT</div>
          <p>
            Rover Seals may transfer all or part of its rights and obligations
            under these Terms to any of its Affiliates or third-parties,
            including, but not limited to, in case of a merger, division,
            partial asset transfer, or full or partial business disposal. The
            user shall not transfer all or part of its obligations under these
            Terms, in any way whatsoever, without the express prior written
            agreement of Rover Seals.
            <br />
            For the purpose of this Agreement, “Affiliate” means, with respect
            to any specified Person, any other Person who, directly or
            indirectly, controls, is controlled by, or is under common control
            with such Person, including without limitation any general partner,
            managing member, officer, director or trustee of such Person, or any
            venture capital fund or registered investment company now or
            hereafter existing that is controlled by one or more general
            partners, managing members or investment advisers of, or shares the
            same management company or investment adviser with, such Person,
            where “control” is defined as directly or indirectly possessing the
            power to direct or cause the direction of the management and
            policies of the Affiliate, whether through ownership of voting
            securities, by contract or otherwise.
          </p>
        </>
        <>
          <div className='sub-text'>12.4. VALIDITY</div>
          <p>
            In the event any one or more of the provisions of these Terms are
            unenforceable, they shall be stricken from the applicable document,
            but the remainder of the Terms shall remain in force. The provisions
            declared unenforceable shall be replaced by the provisions that are
            closest in meaning and scope to the initial provisions.
          </p>
        </>
        <>
          <div className='sub-text'>12.5. ELECTRONIC COMMUNICATION</div>
          <p>
            In accordance with applicable law, the user acknowledges and agrees
            that information exchanged between the user and Rover Seals in
            electronic format (including via email) shall be deemed valid means
            of communication, notice and proof, are equivalent to information in
            paper format.
          </p>
        </>

        <div className='sub-text'>13. INTELLECTUAL PROPERTY RIGHT</div>
        <>
          <div className='sub-text'>13.1. Site Content</div>
          <p>
            Unless otherwise indicated in writing by us, the Site, all content,
            and all other materials contained therein, including, without
            limitation, our logos, and all designs, text, graphics, pictures,
            information, data, software, sound files, other files, and the
            selection and arrangement thereof (collectively,{' '}
            <span className='text-hightlight'>“Site Content”</span>) are the
            proprietary property of Rover Seals or our Affiliates, licensors, or
            users, as applicable. The Rover Seals logo and any Rover Seals
            product or service names, logos, or slogans that may appear on the
            Site or elsewhere are trademarks of Rover Seals or its Affiliates,
            and may not be copied, imitated, or used, in whole or in part,
            without our prior written permission.
            <br />
            <br />
            You may not use any Site Content or link to the Site without our
            prior written permission. You may not use framing techniques to
            enclose any Site Content without our express written consent. In
            addition, the look and feel of the Site Content, including without
            limitation, all page headers, custom graphics, button icons, and
            scripts constitute the service mark, trademark, or trade dress of
            Rover Seals and may not be copied, imitated, or used, in whole or in
            part, without our prior written permission.
            <br />
            <br />
            You have no obligation to provide us with ideas, suggestions,
            materials, or proposals related to our intellectual property,
            products, or Services (
            <span className='text-hightlight'>“Feedback”</span>). If you or any
            of your employees, Affiliates, or contractors sends or transmit any
            Feedback to us through the Site, our social media channels, or by
            mail, email, telephone, or otherwise to Rover Seals or any of its
            directors, employees, representatives, affiliates, subsidiaries or
            agents, Rover Seals is free to use such Feedback irrespective of any
            other obligation or limitation between the parties governing such
            Feedback. You hereby assign to the Rover Seals on your behalf, and
            on behalf of your employees, Affiliates, contractors, and/or agents,
            all rights, titles, and interest in, and to any such Feedback. Rover
            Seals is free to use, without any attribution or compensation to any
            party, any ideas, know-how, concepts, techniques, or other
            intellectual property rights contained in the Feedback, for any
            purpose whatsoever, although Rover Seals is not required to use any
            Feedback.
            <br />
            <br />
            Rover Seal NFT:  By using our Services and/or possessing any Rover
            Seals NFT, you are hereby indicating your agreement to adhere to the
            terms and conditions stated in the Rover Seals NFT License
            Agreement.
          </p>
        </>

        <>
          <div className='sub-text'>14. COPYRIGHT COMPLAINTS</div>
          <p>
            We respect the intellectual property of others, and we ask our users
            to do the same. If you believe that any Metadata associated with a
            Rover Seals NFT or any Site Content available on this Site copies
            your work in a way that constitutes copyright infringement, or that
            your intellectual property rights have been otherwise violated, you
            should notify us of your infringement claim in accordance with the
            procedure set forth below.
            <br />
            <br />
            We will process and investigate notices of alleged infringement and
            will take appropriate actions with respect to any alleged or actual
            infringement. A notification of claimed copyright infringement
            should be emailed to:{' '}
            <a href='mailto:legal@finwallet.com' className='text-hightlight'>
              legal@finwallet.com
            </a>
            .  
            <br />
            <br />
                To be effective, the notification must be in writing and contain
            the following information:
            <br />
            <br />
            <ul>
              <li>
                an electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright or other
                intellectual property interest;
              </li>
              <li>
                a description of the copyrighted work or other intellectual
                property that you claim has been infringed;
              </li>
              <li>
                a description of where the material that you claim is infringing
                is located on the Site, with enough detail that we may find it
                on the Site;
              </li>
              <li>your address, telephone number, and email address;</li>
              <li>
                a statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright or intellectual
                property owner, its agent, or the law;
              </li>
              <li>
                a statement by you, made under penalty of perjury, that the
                above information in your notification is accurate and that you
                are the copyright or intellectual property owner or authorized
                to act on the copyright or intellectual property owner’s behalf.
              </li>
            </ul>
          </p>
        </>

        <>
          <div className='sub-text'>15. TERM AND TERMINATION</div>
          <p>
            THESE TERMS SHALL REMAIN IN FULL FORCE AND EFFECT WHILE YOU USE THE
            SITE. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS, WE
            RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
            LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
            CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
            REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS  OR
            OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
            PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT
            YOU POSTED AT ANY TIME, WITHOUT WARNING, LIABILITY OR
            INDEMNIFICATION, IN OUR SOLE DISCRETION.
          </p>
        </>
        <>
          <div className='sub-text'>16. DISPUTE RESOLUTION</div>
          <p>
            <span className='text-hightlight'>
              Pre-Arbitration Dispute Resolution
            </span>
            . We are always interested in resolving disputes amicably and
            efficiently, and most user concerns can be resolved quickly and to
            the user’s satisfaction by emailing user support at
            legal@finwallet.com. If such efforts prove unsuccessful, a party who
            intends to seek arbitration must first send to the other, by
            certified mail, a written Notice of Dispute (a
            <span className='text-hightlight'>“Notice”</span>).  The Notice to
            Us should be sent to Rover Seals by emailing at{' '}
            <a href='mailto:legal@finwallet.com' className='text-hightlight'>
              legal@finwallet.com
            </a>
            (the <span className='text-hightlight'>“Notice Address”</span>). 
            The Notice must (i) describe the nature and basis of the claim or
            dispute and (ii) set forth the specific relief sought. If such a
            claim is not resolved within sixty (60) calendar days after the
            Notice is received by us, an arbitration proceeding may be
            commenced. During the arbitration, the amount of any settlement
            offer made by us or you shall not be disclosed to the arbitrator
            until after the arbitrator determines the amount, if any, to which
            you or we are entitled.
            <br />
            <br />
            <span className='text-hightlight'>
              Arbitration Agreement and Procedures
            </span>
            .  You agree that if the Parties are unable to resolve a dispute
            through informal negotiations, the dispute (except those disputes
            expressly excluded below) will be finally and exclusively resolved
            by binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION,
            YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be referred to and finally resolved by the
            Singapore International Arbitration Centre (
            <span className='text-hightlight'>“SIAC”</span>) to be resolved
            through confidential, binding arbitration before one arbitrator.
            Arbitration proceedings will be held in Singapore. You agree that
            Disputes will be held in accordance with the SIAC Arbitration Rules
            and Procedures (
            <span className='text-hightlight'>“SIAC Rules”</span>). The most
            recent version of the SIAC Rules is incorporated into these Terms by
            reference. You either acknowledge and agree that you have read and
            understand the SIAC Rules or waive your opportunity to read the SIAC
            Rules and waive any claim that the SIAC Rules are unfair or should
            not apply for any reason. The arbitration hearing shall be conducted
            virtually. The arbitrator will make a decision in writing but need
            not provide a statement of reasons unless requested by either Party.
            The arbitrator must follow applicable law, and any award may be
            challenged if the arbitrator fails to do so. Except where otherwise
            required by the applicable SIAC Rules or applicable law.
            <br />
            <br />
            If for any reason, a dispute proceeds in court rather than
            arbitration, the dispute shall be commenced or prosecuted in the
            court located in Singapore. 
            <br />
            <br />
            In no event shall any dispute brought by either Party related in any
            way to the Site be commenced more than one (1) year after the cause
            of action arose. If this provision is found to be illegal or
            unenforceable, then neither Party will elect to arbitrate any
            dispute falling within that portion of this provision found to be
            illegal or unenforceable and such dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
            <br />
            <br />
            <span className='text-hightlight'>Restrictions.</span> The Parties
            agree that any arbitration shall be limited to the dispute between
            the Parties individually. To the full extent permitted by law, (a)
            no arbitration shall be joined with any other proceeding; (b) there
            is no right or authority for any dispute to be arbitrated on a
            class-action basis or to utilize class action procedures; and (c)
            there is no right or authority for any dispute to be brought in a
            purported representative capacity on behalf of the general public or
            any other persons.
            <br />
            <br />
            <span className='text-hightlight'>Exceptions to Arbitration.</span>
            The Parties agree that the following disputes are not subject to the
            above provisions concerning binding arbitration: (a) any disputes
            seeking to enforce or protect, or concerning the validity of, any of
            the intellectual property rights of a Party; (b) any dispute related
            to, or arising from, allegations of theft, piracy, invasion of
            privacy, or unauthorized use; and (c) any claim for injunctive
            relief. If this provision is found to be illegal or unenforceable,
            then neither Party will elect to arbitrate any dispute falling
            within that portion of this provision found to be illegal or
            unenforceable and such dispute shall be decided by a court of
            competent jurisdiction within the courts listed for jurisdiction
            above, and the Parties agree to submit to the personal jurisdiction
            of that court.
          </p>
        </>
        <>
          <div className='sub-text'>17. MISCELLANEOUS</div>
          <p>
            <span className='text-hightlight'>Governing Law.</span> These Terms
            and your use of the Site are governed by and construed in accordance
            with the laws of Singapore, without regard to its conflict of law
            principles.
            <br />
            <br />
            <span className='text-hightlight'>No Professional Advice.</span> 
            Any information provided by or on behalf of us, or by any other
            users, through the Site (including these Terms) is for informational
            purposes only and should not be construed as professional,
            accounting, tax, or legal advice.  You should not take or refrain
            from taking any action in reliance on any information contained in
            these Terms or on any information provided by or on behalf of us or
            any user through the Site. Before you make any decision to purchase
            or sell any NFT, you should consider seeking independent
            professional advice from persons licensed and qualified in the area
            for which such advice would be appropriate.
            <br />
            <br />
            <span className='text-hightlight'>No Waivers.</span>  No failure or
            delay on our part in the exercise of any power, right, privilege or
            remedy under these Terms shall operate as a waiver of such power,
            right, privilege or remedy; and no single or partial exercise of any
            such power, right, privilege or remedy shall preclude any other or
            further exercise thereof or of any other power, right, privilege or
            remedy.  We shall not be deemed to have waived any claim arising out
            of these Terms, or any power, right, privilege or remedy under these
            Terms, unless the waiver of such claim, power, right, privilege or
            remedy is expressly set forth in a written instrument duly executed
            and delivered on behalf of us, and any such waiver shall not be
            applicable or have any effect except in the specific instance in
            which it is given.
            <br />
            <br />
            <span className='text-hightlight'>Entire Agreement.</span>  These
            Terms and any policies or operating rules posted by Us on the Site
            or in respect to the Site constitute the entire agreement and
            understanding between you and us. 
            <br />
            <br />
            <span className='text-hightlight'>Assignment.</span>  We may assign
            any or all of our rights and obligations to others at any time. We
            shall not be responsible or liable for any loss, damage, delay, or
            failure to act caused by any cause beyond our reasonable control. 
            <br />
            <br />
            <span className='text-hightlight'>Severability.</span>  If any
            provision or part of a provision of these Terms is determined to be
            unlawful, void, or unenforceable, that provision or part of the
            provision is deemed severable from these Terms and does not affect
            the validity and enforceability of any remaining provisions. 
            <br />
            <br />
            <span className='text-hightlight'>Relationship of the Parties</span>
            . There is no joint venture, partnership, employment, or agency
            relationship created between you and us as a result of these Terms
            or use of the Site. 
            <br />
            <br />
            You agree that these Terms will not be construed against us by
            virtue of having drafted them.
          </p>
        </>
      </div>
    </LayoutText>
  )
}

export default Terms
