import React from 'react'

import './MyButton.scss'

function MyButton({
  children,
  isDisable,
  url,
  isBlank = false,
  onClick,
  varian = 'default',
  type = 'normal',
  className,
}) {
  const handleClick = () => {
    typeof onClick === 'function' && onClick()
  }
  return (
    <>
      {!url ? (
        <button
          className={`button button--${varian} button--${type} ${className}`}
          disabled={isDisable}
          onClick={handleClick}
        >
          {children}
        </button>
      ) : (
        <a
          href={url}
          target={isBlank && '_blank'}
          className={`button button--${varian} button--${type} ${className} ${
            isDisable && 'link-disable'
          }`}
        >
          {children}
        </a>
      )}
    </>
  )
}

export default MyButton
