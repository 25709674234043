import React from 'react'

import styles from './ItemInfo.module.scss'
import images from 'assets/images'

function ItemInfo({ item }) {
  return (
    <div className={styles.itemInfoWrapper}>
      <img src={item.image} alt='' className='img-info' />

      <div className='item-info'>
        <div className='content-item' style={{ backgroundColor: item.bgColor }}>
          <img src={images.textBg} alt='' className='bg-content' />

          <div className='head-title'>
            <h5 className='title'>{item.title}</h5>

            <div className='text-description'>{item.description}</div>
          </div>

          {/* <div className='content-item-body-wrapper'>
            {item.data.map((itemParent, i) => {
              return (
                <div className='content-item-body' key={i}>
                  <div className='content-item-body__head'>
                    <span className='icon-web-star icon-star'></span>
                    <div className='sub-title'>{itemParent.title}</div>
                  </div>

                  <div className='content-item-body__text'>
                    {itemParent.text}
                  </div>
                </div>
              )
            })}

            <div>
              {item.dataChild.map((itemChild, i) => {
                return (
                  <div
                    className='content-item-body content-item-body--child'
                    key={i}
                  >
                    <div className='content-item-body__head'>
                      <span className='icon-web-star icon-star'></span>
                      <div className='sub-title'>{itemChild.title}</div>
                    </div>

                    <div className='content-item-body__text'>
                      {itemChild.text}
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ItemInfo
