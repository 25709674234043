import images from 'assets/images'
import React, { useEffect, useRef } from 'react'

import styles from './LayoutText.module.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useResizeWidth from 'hooks/useResizeWidth'

function LayoutText({ children, title, date }) {
  const layoutRef = useRef()
  const history = useLocation()
  const router = useNavigate()
  const { positionY } = useResizeWidth()
  console.log({ positionY })

  const onTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      layoutRef.current.scrollIntoView()
      document.querySelector('#layout').scrollTo(0, 0)
    }, 100)
  }

  const handleOnTop = () => {
    console.log('ok')
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    layoutRef.current.scrollIntoView()
  }

  useEffect(() => {
    onTop()
  }, [history])

  return (
    <div className={styles.layoutWrapper} id='layout' ref={layoutRef}>
      <div className='layout-head'>
        <span
          className='icon-web-arrow-left icon-back'
          onClick={() => router('/')}
        ></span>

        <Link to='/'>
          <img src={images.logo} alt='' className='logo' />
        </Link>
      </div>

      <div className='layout-content'>
        <h3 className='privacy-title'>{title}</h3>

        <div className='date-text'>Date Last Revised: {date}</div>

        {children}
      </div>
    </div>
  )
}

export default LayoutText
