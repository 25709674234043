import images from 'assets/images'
import { routers } from 'commons/routers'
import useResizeWidth from 'hooks/useResizeWidth'
import LandingBody from 'pages/LandingBody'
import Header from 'pages/layouts/Header'
import { useEffect } from 'react'
import Marquee from 'react-fast-marquee'
import { Link, Route, Routes, useLocation } from 'react-router-dom'

const arrSocial = [
  {
    image: 'twitter',
    name: 'Twitter (X)',
    description: 'Take a deep dive',
    link: 'https://twitter.com/RoverSeals',
  },
  {
    image: 'discord',
    name: 'Discord',
    description: 'Get on with us',
    link: 'https://discord.gg/N4ZPdd3N',
  },
  {
    image: 'fin-wallet',
    name: 'Fin wallet',
    description: 'Break new ground with Fin Wallet',
    link: 'https://finwallet.com/',
  },
]

const arrFooter = [
  {
    name: 'Privacy Policy',
    link: '/privacy-policy',
  },
  {
    name: 'Terms of Use',
    link: '/terms',
  },
  {
    name: 'License',
    link: '/license',
  },
]

const arrBanner = [
  {
    image: images.fish1,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish2,
    text: 'ON MY WAY',
  },
  {
    image: images.fish3,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish4,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish1,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish2,
    text: 'ON MY WAY',
  },
  {
    image: images.fish3,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish4,
    text: 'JOIN THE ROVER SQUAD',
  },
]

function App() {
  const { positionY } = useResizeWidth()
  const history = useLocation()
  const isHomePage = history.pathname === '/'

  const renderItemBanner = () => {
    return arrBanner.map((item, i) => {
      return (
        <div className='item-banner' key={i}>
          <img src={item.image} alt='' className='icon-banner' />
          <h6>{item.text}</h6>
        </div>
      )
    })
  }

  const onScrollTop = () => {
    // setTimeout(() => {
    //   window.scrollTo({
    //     top: 0,
    //   })
    //   // layoutRef.current.scrollIntoView()
    //   document.querySelector('#layout') &&
    //     document.querySelector('#layout').scrollTo(0, 0)
    // }, 100)
  }

  const handleOnTop = () => {
    console.log('ok')
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    // layoutRef.current.scrollIntoView()
  }

  useEffect(() => {
    onScrollTop()
  }, [history.pathname])

  return (
    <div className='App text-base'>
      {isHomePage && <Header />}

      <div className={`app-body ${isHomePage && 'app-body--home'}`}>
        <Routes>
          {routers.map((route) => {
            return <Route path={route.path} element={route.component} />
          })}
        </Routes>

        <div className='banner-run-bottom'>
          <Marquee>{renderItemBanner()}</Marquee>
        </div>

        <div className='banner-social'>
          <img src={images.bgText} alt='' className='bg-text' />

          <div className='banner-social-container'>
            <h3>ON YOUR WAY TO BECOME A ROVER</h3>

            <div className='list-social'>
              {arrSocial.map((item, i) => {
                return (
                  <a href={item.link} key={i} target='_blank' rel='noreferrer'>
                    <div className='item-social'>
                      <div className='head-icon'>
                        <span
                          className={`icon-web-${item.image} icon-social icon-social--${item.image}`}
                        ></span>
                        <span className='icon-web-arrow-rightup icon-arrow'></span>
                      </div>

                      <h6 className='head-title'>{item.name}</h6>

                      <div className='text-description'>{item.description}</div>
                    </div>
                  </a>
                )
              })}
            </div>

            <div className='list-footer'>
              {arrFooter.map((item, i) => {
                return (
                  <Link to={item.link} className='item-footer'>
                    {item.name}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`top-icon ${positionY >= 700 && 'top-icon--show'}`}
        onClick={handleOnTop}
      >
        <img src={images.iconUp} alt='' className='button-custom' />
      </div>
    </div>
  )
}

export default App
