import LandingBody from 'pages/LandingBody'
import License from 'pages/License'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import Terms from 'pages/Terms'

const routers = [
  {
    path: '/',
    component: <LandingBody />,
  },
  {
    path: '/privacy-policy',
    component: <PrivacyPolicy />,
  },
  {
    path: '/terms',
    component: <Terms />,
  },
  {
    path: '/license',
    component: <License />,
  },
]

export { routers }
