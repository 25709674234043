import React, { useRef } from 'react'

import styles from './LandingBody.module.scss'
import images from 'assets/images'
import MyButton from 'pages/components/MyButton'
import Marquee from 'react-fast-marquee'
import ItemCard from './ItemCard'
import ItemInfo from './ItemInfo'
import useResizeWidth from 'hooks/useResizeWidth'

const arrBanner = [
  {
    image: images.fish1,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish2,
    text: 'ON MY WAY',
  },
  {
    image: images.fish3,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish4,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish1,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish2,
    text: 'ON MY WAY',
  },
  {
    image: images.fish3,
    text: 'JOIN THE ROVER SQUAD',
  },
  {
    image: images.fish4,
    text: 'JOIN THE ROVER SQUAD',
  },
]

const arrCard = [
  {
    id: 1,
    cardFont: images.cardFront1,
    cardBack: images.cardBack1,
  },
  {
    id: 2,
    cardFont: images.cardFront2,
    cardBack: images.cardBack2,
  },
  {
    id: 3,
    cardFont: images.cardFront3,
    cardBack: images.cardBack3,
  },
  {
    id: 4,
    cardFont: images.cardFront4,
    cardBack: images.cardBack4,
  },
]

const arrInfo = [
  {
    image: images.meso,
    bgImage: images.bgMeso,
    bgColor: '#c1a9fd',
    title: 'THE TWILIGHT ZONE',
    description:
      'A networking hub where different animal species exchange vital information with Rover Seals.',
    data: [
      {
        title: 'Topographic',
        text: `Extending from 200 to 1,000 meters below the ocean's surface`,
      },
      {
        title: 'Conditions',
        text: `Basking in the most light among all the regions. It provides an adequate habitat for various animal species to flourish`,
      },
      {
        title: 'Facility',
        text: `Infrastructure: Humble, Conventional, and Partly Traditional`,
      },
    ],
    dataChild: [
      {
        title: 'Governance',
        text: `Ruling by OTA and ODO`,
      },
      {
        title: 'Living standard',
        text: `Moderate`,
      },
    ],
  },
  {
    image: images.bathyaizone,
    bgImage: images.bgBathyaizone,
    bgColor: '#ffe675',
    title: 'THE MIDNIGHT ZONE',
    description: `A formidable region, mostly known for its manufacturing activity, showcasing Seilantis' strength.`,
    data: [
      {
        title: 'Topographic',
        text: `Extending from 1,000 to 4,000 meters below the ocean’s surface`,
      },
      {
        title: 'Conditions',
        text: `Receiving less light and is home to larger animal species`,
      },
      {
        title: 'Facility-Infrastructure',
        text: `Grand buildings, Sturdy fences, Imposing walls, and Factories`,
      },
    ],
    dataChild: [
      {
        title: 'Governance',
        text: `Ruling by MONA and FOCI`,
      },
      {
        title: 'Living standard',
        text: `Moderate`,
      },
    ],
  },
  {
    image: images.abysszone,
    bgImage: images.bgAbysszone,
    bgColor: '#00cec2',
    title: 'THE ABYSS ZONE',
    description:
      'The most hectic area in Seilantis where trading and exchanging activities take place.',
    data: [
      {
        title: 'Topographic',
        text: `Below the Bathyal Zone, a place of underwater mountains and rocky formations`,
      },
      {
        title: 'Conditions',
        text: `A harsh environment, characterized by extreme pressure and chilling temperatures`,
      },
      {
        title: 'Facility-Infrastructure',
        text: `Distinctive architectures built on rocky formations`,
      },
    ],
    dataChild: [
      {
        title: 'Governance',
        text: `Ruling by FOCI and OTA`,
      },
      {
        title: 'Living standard',
        text: `High, Wealthy`,
      },
    ],
  },
  {
    image: images.hadal,
    bgImage: images.bgHadal,
    bgColor: '#ffb96c',
    title: 'THE HADAL ZONE',
    description:
      'A place of breathtaking natural beauty where only a few elite and rare Rove Seals can adapt to extreme conditions.',
    data: [
      {
        title: 'Topographic',
        text: `Below the Abyss Zone, a region of deep trenches and awe-inspiring rocky formations, breathtaking natural beauty`,
      },
      {
        title: 'Conditions',
        text: `Extreme, even more unforgiving than the Abyss Zone`,
      },
      {
        title: 'Facility-Infrastructure',
        text: `A royal realm with grand castles and towering buildings`,
      },
    ],
    dataChild: [
      {
        title: 'Governance',
        text: `Top rarity and elite Rover of all tribes`,
      },
      {
        title: 'Living standard',
        text: `Extremely wealthy`,
      },
    ],
  },
]

function LandingBody() {
  const isWindow = typeof window !== 'undefined'
  const { widthResize, isMobile } = useResizeWidth()
  const titleRef = useRef(null)
  const cardRef = useRef(null)
  const isTabletReszie = widthResize <= 1024
  const isMobileReszie = widthResize < 768

  const handleDirection = () => {
    // cardRef.current.scrollIntoView()
    titleRef.current.scrollIntoView()
  }

  const renderItemBanner = () => {
    return arrBanner.map((item, i) => {
      return (
        <div className='item-banner' key={i}>
          <img src={item.image} alt='' className='icon-banner' />
          <h6>{item.text}</h6>
        </div>
      )
    })
  }

  return (
    <div className={styles.landingBody}>
      <div className='banner'>
        <img
          src={
            images[
              isMobileReszie
                ? 'thumbnailMobile'
                : !isMobileReszie && isTabletReszie
                ? 'thumbnailTablet'
                : 'thumbnail'
            ]
          }
          alt=''
          className='banner-img'
        />

        <img src={images.logo} alt='' className='logo' />

        <div className='direction-icon' onClick={handleDirection}>
          <img src={images.buttonIcon} alt='' className='button-custom' />
          <img
            src={images.buttonIconActive}
            alt=''
            className='button-custom button-custom--hover'
          />
          <img src={images.cloud} alt='' className='cloud' />
          Let's rove
        </div>
      </div>

      <div className='body-content'>
        <img src={images.bgFish1} alt='' className='img-bg img-bg--fish-1' />
        <img src={images.bgFish2} alt='' className='img-bg img-bg--fish-2' />
        <img src={images.bgFish3} alt='' className='img-bg img-bg--fish-3' />
        <img src={images.octopush} alt='' className='img-bg img-bg--fish-4' />
        <img src={images.octopush2} alt='' className='img-bg img-bg--fish-5' />
        <img src={images.ship} alt='' className='img-bg img-bg--ship' />

        <div className='banner-run'>
          <Marquee>{renderItemBanner()}</Marquee>
        </div>

        <div className='card-wrapper' ref={cardRef}>
          <div className='card-container'>
            <img
              src={images[!isMobile ? 'textTitle1' : 'textTitle1Mobile']}
              alt=''
              className='text-title'
              ref={titleRef}
            />
          </div>

          <div className='card-content'>
            <div className='card-content--left'>
              {arrCard.slice(0, 2).map((item, i) => {
                return <ItemCard key={i} item={item} />
              })}
            </div>

            <div className='card-content--right'>
              {arrCard.slice(2).map((item, i) => {
                return <ItemCard key={i} item={item} />
              })}
            </div>
          </div>

          <div className='action-explore'>
            <h6>Explore more on</h6>
            <MyButton
              className='dagora-button'
              url='https://dagora.xyz/launchpad/roverseals'
              isBlank
            >
              <img src={images.dagora} alt='' className='icon-logo' />
            </MyButton>
          </div>
        </div>

        <div className='info-wrapper'>
          {/* <h1>Let's rover through the mystical underwater world of Seilantis!</h1> */}
          <div className='text-title-box'>
            <img
              src={images[!isMobile ? 'textTitle2' : 'textTitle2Mobile']}
              alt=''
              className='text-title-2'
            />
          </div>

          <div className='info-content'>
            {arrInfo.map((item, i) => {
              return (
                <div className='info-item' key={i}>
                  <ItemInfo item={item} />
                </div>
              )
            })}
          </div>
        </div>

        <div className='roadmap-wrapper'>
          <div className='roadmap'>
            <div className='roadmap-content'>
              <img src={images.fish5} alt='' className='icon-fish' />
              {/* <h3>Roadmap & Team/KOLs will be announced soon!</h3> */}
              <div className='text-box'>
                <img
                  src={images[!isMobile ? 'textTitle3' : 'textTitle3Mobile']}
                  alt=''
                  className='text-title-3'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingBody
