import React, { useEffect, useState } from 'react'

import './ItemCard.scss'
import useResizeWidth from 'hooks/useResizeWidth'

function ItemCard({ item }) {
  const { widthResize } = useResizeWidth()
  const [isFlip, setIsFlip] = useState(false)
  const isDesktop = widthResize >= 1024

  const handleFlip = () => {
    if (isDesktop) {
      return null
    } else {
      setIsFlip(!isFlip)
    }
  }

  useEffect(() => {
    isDesktop && setIsFlip(false)
  }, [isDesktop])

  return (
    <div
      className={`item-card ${isFlip && 'item-card--flip'}`}
      onClick={handleFlip}
    >
      <div className='img-card'>
        <img
          src={item.cardFont}
          alt=''
          className='img-card-content img-card-content--font'
        />
        <img
          src={item.cardBack}
          alt=''
          className='img-card-content img-card-content--back'
        />
      </div>
    </div>
  )
}

export default ItemCard
