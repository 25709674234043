import React, { useEffect, useState } from 'react'

import styles from './Header.module.scss'
import MyButton from 'pages/components/MyButton'
import useResizeWidth from 'hooks/useResizeWidth'

function Header() {
  const { widthResize, positionY } = useResizeWidth()
  const [isShowMenu, setIsShowMenu] = useState(false)
  const isMobile = widthResize <= 1024

  const handleShowMenu = () => {
    setIsShowMenu(!isShowMenu)
  }

  useEffect(() => {
    if (!isMobile) setIsShowMenu(false)
  }, [isMobile])

  return (
    <div className={styles.headerWrapper}>
      {isMobile && (
        <MyButton
          varian='secondary'
          type='square'
          className='button-menu'
          onClick={handleShowMenu}
        >
          <span className='icon-web-menu icon-menu'></span>
        </MyButton>
      )}

      <div className={`header ${isShowMenu && 'header--show-menu'}`}>
        {isMobile && (
          <MyButton
            varian='secondary'
            type='square'
            className='button-menu button-menu--close'
            onClick={handleShowMenu}
          >
            <span className='icon-web-close icon-menu'></span>
          </MyButton>
        )}

        <div
          className={`header-container ${
            isMobile && 'header-container--mobile'
          }`}
        >
          <MyButton
            varian='secondary'
            className='button-custom button-custom--full'
            url='https://dagora.xyz/launchpad/roverseals'
            isBlank
          >
            Marketplace
          </MyButton>

          <MyButton
            varian='secondary'
            className='button-custom button-custom--full'
            url='https://finwallet.com/'
            isBlank
          >
            <span>Powered by </span> Fin Wallet
          </MyButton>

          <MyButton
            varian='secondary'
            type='square'
            className='button-social'
            url='https://twitter.com/RoverSeals'
            isBlank
          >
            <span className='icon-web-twitter icon-social'></span>
          </MyButton>

          <MyButton
            varian='secondary'
            type='square'
            className='button-social button-social--discord'
            url='https://discord.gg/RvzcBUpuv8'
            isBlank
          >
            <span className='icon-web-discord icon-social'></span>
          </MyButton>
        </div>
      </div>
    </div>
  )
}

export default Header
