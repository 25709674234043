import React from 'react'

import styles from './License.module.scss'
import LayoutText from 'pages/layouts/LayoutText'

function License() {
  return (
    <LayoutText title='ROVER SEALS NFT LICENSE AGREEMENT' date='August, 2023'>
      <div className={styles.licenseWrapper}>
        <p>
          This ROVER SEALS NFT License Agreement (the{' '}
          <span className='text-hightlight'>“License”</span>) (together with our
          Terms of Use, Privacy Policy and any other documents referred to
          herein) is a legally binding agreement entered into between FinWhale
          Labs, LLC and you. When you acquire an ROVER SEALS NFT, you own all
          personal property rights to the token underlying the ROVER SEALS NFT
          (e.g., the right to freely sell, transfer, or otherwise dispose of
          that ROVER SEALS NFT), but you do not own the associated artwork,
          brand, or other intellectual property associated with that ROVER SEALS
          NFT, except for the specific licensed rights set forth below.
        </p>

        <div className='sub-text'>DEFINITIONS</div>

        <p>
          <span className='text-hightlight'>“Affiliate”</span> means, with
          respect to any specified party, any entity, individual, firm or
          corporation, directly or indirectly, controls, is controlled by, or is
          under common control with such party, including without limitation any
          general partner, managing member, officer, director or trustee of such
          party, or any venture capital fund or registered investment company
          now or hereafter existing that is controlled by one or more general
          partners, managing members or investment advisers of, or shares the
          same management company or investment adviser with, such party, where
          “control” is defined as directly or indirectly possessing the power to
          direct or cause the direction of the management and policies of the
          Affiliate, whether through ownership of voting securities, by contract
          or otherwise.
          <br />
          <br />
          <span className='text-hightlight'>“Art”</span> means any digital
          creation, art, design, and drawings created by its author, and first
          released to the public by the Creator, that may be associated with a
          ROVER SEALS NFT that can be transferred or licensed to an Owner
          (subject to the limitations set forth herein).
          <br />
          <br />
          <span className='text-hightlight'>"NFT"</span> means any
          blockchain-tracked, non-fungible token, issued and tradable on a
          blockchain. “ROVER SEALS NFT” means any ROVER SEALS branded or themed
          NFT created by or on behalf of the Creator.
          <br />
          <br />
          <span className='text-hightlight'>“Creator”</span> means FinWhale
          Labs, LLC, the entity which created and released to the public ROVER
          SEALS NFTs, and related Art.
          <br />
          <br />
          <span className='text-hightlight'>“Effective Date”</span> means the
          date on which this License becomes attached to the ROVER SEALS NFT for
          the first time it is released and acquired, or thereinafter
          subsequently acquired, by the Owner, and this License shall be deemed
          as entering into force on that date.
          <br />
          <br />
          <span className='text-hightlight'>“Owner”</span> means the current
          owner of the ROVER SEALS NFT. In case of transfer of ownership,
          including via resale, the recipient becomes the sole owner of the
          purchased ROVER SEALS NFT. There can be several different owners of
          each ROVER SEALS NFT throughout time but only one Owner at a time.
          <br />
          <br />
          <span className='text-hightlight'>“Own”</span> means, with respect to
          a ROVER SEALS NFT, an ROVER SEALS NFT that one has purchased or
          otherwise rightfully acquired from a legitimate source, where proof of
          such purchase is recorded on the relevant blockchain, thus becoming
          the Owner of the purchased ROVER SEALS NFT.
          <br />
          <br />
          <span className='text-hightlight'>“Resale”</span> means the sale by
          the Owner of the purchased ROVER SEALS NFT, and rights to the Art, to
          a subsequent buyer, which shall own the purchased ROVER SEALS NFT and
          rights to the Art (to the extent the rights to the Art set forth in
          this License) upon its effective purchase and thus become its new
          Owner. 
          <br />
          <br />
          <span className='text-hightlight'>“Assignment”</span> means any and
          all assignment by the Owner of all its rights, titles and interests in
          and to the intellectual property rights on the Art (to the extent
          actually assignable) to the subsequent buyer of the purchased ROVER
          SEALS NFT.
        </p>

        <div className='sub-text'>PURPOSE</div>

        <p>
          This License is intended to govern the terms and conditions of
          assignment of rights, titles and interests in and to the intellectual
          property rights on the Art to which this License is attached.
          <br />
          <br />
          The License shall govern the primary Assignment under which the
          Creator assigns to the primary Owner certain intellectual property
          rights on the Art and all secondary Assignments between the Owner and
          subsequent buyers of the ROVER SEALS NFT, where applicable.
          <br />
          <br />
          The License shall be effective as of the Effective Date and remain in
          force as long as it is still attached to the ROVER SEALS NFT. By
          acquiring the purchased ROVER SEALS NFT, and related Art, in any way
          whatsoever, the Owner hereby acknowledges having read the content of
          this License and agrees to abide by its terms and conditions set forth
          herein.
          <br />
          <br />
          In creating this License, we acknowledge that the way people create,
          use, and monetize their NFTs is evolving and changing. We have
          developed this License seeking to protect the hard work and creativity
          of the Creator, on the one hand, while granting Owners the freedom and
          flexibility to fully enjoy their ROVER SEALS NFT and related Art. We
          are open to our community’s feedback on this License and may revise
          and publish amended versions from time to time.
        </p>

        <div className='sub-text'>1. OWNERSHIP RIGHTS</div>

        <p>
          It is expressly agreed that the License related to the Art is granted
          in consideration of the price paid by the buyer to purchase the ROVER
          SEALS NFT, as listed on any NFT marketplace on which it is acquired or
          by free mint, allow list, and/or public sale (the “Purchase Price”),
          where applicable. Notwithstanding the foregoing, the Creator shall
          also be entitled to a compensation on any Resale of the complete work
          in accordance with the percentage of commission determined by the NFT
          marketplace on which said Resale is completed.
        </p>

        <div className='sub-text-2'>Your Ownership of Rover Seals NFT</div>

        <p>
          When you purchase a ROVER SEALS NFT, you are the Owner of that Rover
          Seals NFT. In case of transfer of ownership, including via Resale, the
          subsequent buyer becomes the sole successive Owner of the purchased
          ROVER SEALS NFT. Once the Rover Seals NFT is transferred to the
          subsequent Owner, you are no longer the Owner of that Rover Seals NFT.
          There can be several different owners of each Rover Seals NFT
          throughout time but only one Owner at a time. 
          <br />
          <br />
          The Owner has full ownership rights in the ROVER SEALS NFT, including
          the right to store, sell and transfer the ROVER SEALS NFT. However,
          the Owner acknowledges and agrees that this purchase of the ROVER
          SEALS NFT does not provide any rights, express or implied, in
          (including, without limitation, any copyrights or other intellectual
          property rights in or to) the ROVER SEALS NFT and Art associated with
          the Rover Seals NFT other than the limited licenses granted under this
          License. The Owner’s rights and interests in its ROVER SEALS NFT, and
          any Art provided by this License will immediately terminate upon any
          subsequent sale, transfer, dispossession, burning, or other
          relinquishment of their ROVER SEALS NFT.
          <br />
          <br />
          Any use of the Art other than as expressly authorized herein, without
          the prior explicit written permission of the Creator, is strictly
          prohibited and shall immediately terminate all rights and licenses
          granted by this License. Such unauthorized use may also violate
          applicable laws including copyright and trademark laws and applicable
          communications regulations and statutes.
          <br />
          <br />
          Nothing in this License be interpreted as granting any license of
          intellectual property rights to you other than as explicitly set forth
          in this License.
        </p>

        <div className='sub-text-2'>
          Creator’s Ownership of IP in Rover Seals NFT
        </div>

        <p>
          The Creator owns (but license to the Owner) the intellectual property
          rights in Art related to the Owner’s ROVER SEALS NFT. The Creator owns
          all rights, titles, and interests in and to the Art including any and
          all copyrights, trademarks, and other intellectual property rights
          therein (<span className='text-hightlight'>“IP”</span>). However,
          Creator grant the Owner the license to use the Art associated with the
          Owner’s ROVER SEALS NFT (
          <span className='text-hightlight'>“Owner Rover Seals Art”</span>),
          subject to the terms and conditions of this License, for as long as
          the Owner holds ROVER SEALS NFT.
        </p>

        <div className='sub-text-2'>Your Ownership of Derivative Work</div>

        <p>
          The Owner owns all rights, titles and interests in and to any
          “derivative work”, as defined by applicable law, based on the Owner
          Rover Seals Art created during the License Terms (defined below)
          (“Derivative Work”); provided, however, that (i) the Creator retain
          the copyright in the Art underlying any Derivative Work; (ii) the
          Owner’s use of any Derivative Work during and after the License Term
          is subject to this License; (iii) the use of any Derivative Work after
          the License Term may require a license from the current Owner of the
          ROVER SEALS NFT; and (iv) the Owner grants to the Creator and/or its
          Affiliates an irrevocable, perpetual, non-exclusive, universe-wide,
          royalty-free, sublicensable license to publicly display and otherwise
          use your Derivative Work for the purpose of promoting the ROVER SEALS
          NFTs.
        </p>

        <div className='sub-text'>2. ASSIGNMENT</div>

        <p>
          The Owner shall have the right to Resale the purchased Rover Seals
          NFT, and the license related to Art, on a marketplace that allows the
          purchase and sale of the Rover Seals NFTs. The Owner shall provide its
          best efforts to Resale the purchased Rover Seals NFT on a marketplace
          which (i) cryptographically verifies each ROVER SEALS NFT Owner’s
          rights to display the Art in order to list it for sale, to ensure that
          only the actual Owner can display the Art for Resale, and (ii)
          provides for a percentage of the Resale price to be automatically
          granted to the Creator upon Resale. 
          <br />
          <br />
          Any such Owner shall have the right to sub-license, assign or
          otherwise transfer to a third-party all or part of the license related
          to Art hereby granted as per this License, for any purpose whatsoever.
          <br />
          <br />
          In case of transfer of ownership of the purchased ROVER SEALS NFT, in
          any way whatsoever, including Resale, the Owner agrees to assign to
          the subsequent buyer of the purchased ROVER SEALS NFT all rights and
          obligations under this License, under the same conditions.
          <br />
          <br />
          This License shall govern any Resale as long as it is still attached
          to the purchased ROVER SEALS NFT and provided no other agreement has
          been concluded between the Owner and the subsequent buyer, which shall
          only apply between the parties of said agreement.
        </p>

        <div className='sub-text'>3. LICENSE GRANT</div>

        <div className='sub-text-2'>License Grant</div>

        <p>
          Subject to your acceptance of, and compliance with, this License and
          applicable laws, upon lawfully acquiring your ROVER SEALS NFT and, for
          so long as you hold ROVER SEALS NFT (the{' '}
          <span className='text-hightlight'>“License Term”</span>), the Creator
          grants to you the following rights:
          <br />
          <br />
          (1) <span className='text-hightlight'>Personal Use:</span> a
          non-exclusive, worldwide, royalty-free, sub-licensable license to use,
          reproduce, display, modify, and create Derivative Works of the Owner
          Rover Seals Art for your ROVER SEALS NFT for personal, non-commercial
          use (such as to display in your wallet, as a profile picture, to
          create a pixel-art version for use in a third party platform).
          <br />
          <br />
          (2) <span className='text-hightlight'>Commercial Use:</span> a
          non-exclusive, worldwide, royalty-free, sub-licensable (but only to
          service providers assisting you with a Commercial Use) license to use,
          copy, and display the Owner Rover Seals Art for your ROVER SEALS NFT,
          for the purpose of commercializing and promoting their own goods and
          services, which shall include the right to produce and sell goods as
          well as services that includes, contains, or consists of the graphical
          image of the Owner Rover Seals Art (“Commercial Use”). Examples of
          such Commercial Use would be the use of the graphical image of the
          Owner Rover Seals Art to produce and sell merchandise products
          (T-shirt etc.). Owner is also granted a limited, worldwide,
          non-exclusive license to copy, access, use and otherwise exploit the
          Owner Rover Seals Art, including but not limited to sublicensing it,
          for the purpose of commercializing and promoting third party goods and
          services in a manner that includes, contains, or consists of the
          graphical image of the Owner Rover Seals Art, for any project for
          which it takes a sublicense and/or otherwise utilizes the Owner Rover
          Seals Art.
          <br />
          <br />
          The license in this sub-section (2) does not include a right to use
          and otherwise exploit your Derivative Work for Commercial Use.
          <br />
          <br />
          This limited license for Commercial Use is subject to the following
          conditions:
          <br />
          <br />
          <ul>
            <li>
              The License extends only to the complete selection and arrangement
              of all base layers, features, attributes, and other elements that
              comprise the Owner Rover Seals Art. Thus, while the License allows
              the Owner to create Derivative Works, the License does not grant
              the Owner rights in any individual element of the Owner Rover
              Seals Art, or a license to exploit any individual element separate
              and apart from the Owner Rover Seals Art. For example, the License
              allows you to create three-dimensional renditions of, and to add
              new clothing to, the Owner Rover Seals Art, but does not allow you
              to extract individual features (e.g., cloth, accessories) for use
              in a separate work.
            </li>
            <li>
              The Owner and/or any sublicensee may not use and/or sublicense
              their ROVER SEALS NFT or related Art to promote, advertise,
              market, or sell any third party good or service without such use
              and/or sublicense being expressly allowed under this License or
              otherwise approved by the Creator in writing;
            </li>
            <li>
              The Owner and/or any sublicensee does not have any right to modify
              or present its ROVER SEALS NFT or related Art in any way or any
              context that depicts hatred, intolerance, violence, cruelty, or
              anything else that could reasonably be found to constitute hate
              speech, be offensive, demeaning, not comport with the inclusive
              values of the Rover Seals brand and community, or otherwise
              infringe upon the rights of others or the Rover Seals community;
            </li>
            <li>
              The Owner and/or any sublicensee shall not use the name “Rover
              Seal”, “Rover Seals” or any Rover Seals name, logo or trademark,
              the name of any Rover Seals characters (as applicable), or any
              animation, characters, themes or other material related to the
              Creator’s own digital content;
            </li>
            <li>
              The Owner and/or any sublicensee shall not combine its Rover Seals
              with any other Rover Seals or modified Rover Seals that it does
              not own, except on explicit permission of the other Rover Seals
              Owner;
            </li>
            <li>
              The Owner and/or any sublicensee may not use their Rover Seals NFT
              or related Art in a manner that violates applicable law;
            </li>
            <li>
              The Owner and/or any sublicensee may not attempt to trademark,
              copyright, or otherwise acquire additional intellectual property
              rights in or to “Rover Seal”, “Rover Seals”, any Rover Seals NFT
              or related Art or content; and
            </li>
            <li>
              All rights not expressly granted herein are reserved by the
              Creator.
            </li>
          </ul>
          <br />
          In the event that a Owner makes use of the limited, worldwide,
          non-exclusive license to copy, access, use and otherwise exploit the
          Owner’s ROVER SEALS NFT and related Art for their own commercial
          purposes as allowed for by this License, the Owner represents and
          warrants that they will take all necessary steps to undertake their
          commercial activities in a legal manner, including but not limited to
          forming any needed corporate entity, becoming duly organized, validly
          existing and duly qualified to do business in all jurisdictions where
          those activities are carried out, as well as remaining in good
          standing under and abiding by all applicable laws, rules and
          regulations that relate to those activities. The Owner also represents
          and warrants that they have requisite power and authority to carry out
          their commercial activities, and that includes but is not limited to,
          having sufficient insurance to ensure they can meet their obligations
          to their customers and indemnification obligations to the Creator.
          <br />
          <br />
          In the event that the Owner makes use of the limited, worldwide,
          non-exclusive license to copy, access, use and otherwise exploit the
          Owner’s ROVER SEALS NFT and related Art by granting a sublicense
          and/or otherwise allowing third parties to use their ROVER SEALS NFT
          as allowed for by this License, the Owner represents and warrants that
          they will take all necessary steps to ensure that the third party is
          undertaking their commercial activities in a legal manner, including
          but not limited to forming any needed corporate entity, becoming duly
          organized, validly existing and duly qualified to do business in all
          jurisdictions where those activities are carried out, as well as
          remaining in good standing under and abiding by all applicable laws,
          rules and regulations that relate to those activities. The Owner also
          represents and warrants that they have requisite power and authority
          to carry out their commercial activities, and that includes but is not
          limited to, having sufficient insurance to ensure they can meet their
          obligations to their customers and indemnification obligations to the
          Creator.
          <br />
          <br />
          Moreover, the Owner shall indemnify, defend, and hold harmless the
          Creator and/or Rover Seals and/or its Affiliates from and against any
          losses, damages, claims, actions, demands, judgments, liability and/or
          suits including reasonable attorneys’ fees, as incurred, that may be
          asserted by any persons or entities arising out of or related to the
          Owner’s use and/or sublicensing of the ROVER SEALS NFT and related Art
          for commercial purposes, as well as any breach of such Owner’s
          warranties and representations in this License.
        </p>

        <div className='sub-text-2'>License Term </div>
        <p>
          The foregoing limited Licenses apply only during the License Term.
          This License commences on the date the you successfully acquire Rover
          Seals NFT and terminates immediately at such a point that the you no
          longer owns such Rover Seals NFT. Moreover, if you exceed the scope of
          this License without entering into a broader license agreement with or
          obtaining an exemption, you acknowledge and agree that: (i) you are in
          breach of this License; (ii) in addition to any remedies that may be
          available to Creator at law or in equity, the Creator may immediately
          terminate this License, without the requirement of notice; and (iii)
          you will be responsible to reimburse Creator for any fees, costs and
          expenses incurred by Creator during the course of enforcing the terms
          of this license against you, including but not limited to accounting
          and legal fees, costs and expenses.
          <br />
          <br />
          If, during the License Term, you create and make available to the
          public a work using the Owner Rover Seals Art (
          <span className='text-hightlight'>“Published Work”</span>), you may
          not continue to use and exploit that Published Work after the License
          Term.  
        </p>

        <div className='sub-text'>4. MORAL RIGHTS</div>
        <p>
          The Creator shall have the right to enforce, on behalf of the author,
          all moral rights of the author of the Art, as provided by applicable
          law, which shall remain perpetual, inalienable, imprescriptible and,
          in general, absolute.
          <br />
          <br />
          The Owner hereby undertakes to abide, and make its contractors,
          including licensees, sublicensees, subsequent buyers, and third
          parties abide by the author’s moral rights in any and all use of the
          Art, and in particular the following rights:
          <br />
          <br />
          (i) right of authorship, which shall entitle the Creator to demand and
          claim at any time that the author’s chosen name be mentioned on any
          mode of publication of the Art; and
          <br />
          <br />
          (ii) right to preserve the integrity of the Art, which shall entitle
          the Creator to oppose any modification, deletion or addition likely to
          alter the art, or any use of the art that may alter its spirit,
          devaluate it or otherwise be deemed prejudicial to the reputation of
          the Creator or the Rover Seals brand or community.
        </p>

        <div className='sub-text'>5. TRADEMARKS</div>
        <p>
          When using the ROVER SEALS NFT and related Art, in any way whatsoever,
          including but not limited to its publication, exploitation, and/or
          promotion, the Owner shall not use the trademarks, service marks, or
          proprietary words or symbols of the Creator and/or its Affiliates, to
          the extent otherwise permitted by applicable law or by written
          agreement of the Creator. 
          <br />
          <br />
          By exception, when using the Art for non-commercial purposes only, the
          Owner shall have the right to reference the Creator by using the
          denomination “Rover Seals”, unless informed otherwise by the Creator.
          The Owner shall take care that such use shall not, in any way
          whatsoever, damage or adversely impact the Creator’s reputation, the
          Rover Seals brand or community.
          <br />
          <br />
          The Creator shall have the right but not the obligation, at its sole
          discretion, to promote, including through social media, any public use
          of the Art by the Owner. The Owner shall not use the Art in a way that
          would or could present the Creator as endorsing, recommending or
          favoring, in any way whatsoever, the Owner and/or its use of the Art,
          without the consent and permission of the Creator. 
          <br />
          <br />
          The trademarks, service marks, trade names and logos, including “Rover
          Seal”, “Rover Seals”, and any third-party marks used and displayed
          through the Services are trademarks of the Creator and/or its
          Affiliates, and may not be used by a Owner other than to factually
          describe its ROVER SEALS NFT or Rover Seals enhancement when listing
          it for sale or distribution or for non-commercial purposes. We retain
          the right to monitor and police the use of our trademarks, including
          but not limited to: (a) take all reasonable measures, including
          without limitations bringing civil actions for trademark infringement,
          to stop the use of marks which, in our sole judgment, are the same as
          or confusingly similar to our trademarks and (b) take all reasonable
          measures, including without limitations bringing proceedings in any
          jurisdiction, to prevent the registration of marks which, in our sole
          judgment, are the same as or confusingly similar to the trademarks.
        </p>

        <div className='sub-text'>6. COPYRIGHT COMPLAINTS</div>
        <p>
          We respect the intellectual property of others, and we ask our users
          to do the same. If you believe that any Art associated with a ROVER
          SEALS NFT copies your work in a way that constitutes copyright
          infringement, or that your intellectual property rights have been
          otherwise violated, you should notify us of your infringement claim in
          accordance with the procedure set forth below.
          <br />
          <br />
          We will process and investigate notices of alleged infringement and
          will take appropriate actions with respect to any alleged or actual
          infringement. A notification of claimed copyright infringement should
          be emailed to:{' '}
          <a href='mailto:legal@finwallet.com' className='text-hightlight'>
            legal@finwallet.com
          </a>
          .     
          <br />
          <br />
          To be effective, the notification must be in writing and contain the
          following information:
          <br />
          <br />
          <ul>
            <li>
              an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright or other intellectual
              property interest;
            </li>
            <li>
              a description of the copyrighted work or other intellectual
              property that you claim has been infringed;
            </li>
            <li>your address, telephone number, and email address;</li>
            <li>
              a statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright or intellectual
              property owner, its agent, or the law;
            </li>
            <li>
              a statement by you, made under penalty of perjury, that the above
              information in your notification is accurate and that you are the
              copyright or intellectual property owner or authorized to act on
              the copyright or intellectual property owner’s behalf.
            </li>
          </ul>
        </p>

        <div className='sub-text'>7. MODIFICATIONS AND DERIVATIVE WORKS</div>
        <p>
          We recognize that you might want to create modifications and
          Derivative Works of your Owner Rover Seals Art, and we allow you to do
          so under the scope of the licenses granted above. However, you
          acknowledge and agree that the Creator may also modify, create
          derivative works of, and update any Rover Seals Art and may create
          works of authorship similar or identical to your own adaptations,
          Derivative Works, and modifications of any Rover Seals Art.
          Accordingly, on behalf of yourself and your heirs, successors and
          assigns, you irrevocably covenant and agree not to assert or bring any
          suit, claim, demand or challenge against the Creator or its Affiliates
          or licensees in connection with their use of any Rover Seals Art or
          any adaptations, Derivative Works, and modifications thereto, even if
          such artwork or content is similar to or the same as any adaptations,
          Derivative Works, or modifications in any Rover Seals Art that have
          been created by you.
        </p>

        <div className='sub-text'>
          8. DISCLAIMERS, LIMITATIONS OF LIABILITY, AND INDEMNIFICATION
        </div>
        <p>
          <spna className='text-hightlight'>DISCLAIMERS.</spna> YOUR ACCESS TO
          AND USE OF THE ROVER SEALS NFT AND RELATED ART IS AT YOUR OWN RISK.
          YOU UNDERSTAND AND AGREE THAT THE ROVER SEALS NFTS AND RELATED ART ARE
          PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT
          LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER
          APPLICABLE LAW, THE CREATOR AND ITS AFFILIATES, OFFICERS, DIRECTORS,
          EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS AND LICENSORS (THE
          “CREATOR ENTITIES”) DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER
          EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE OR NON-INFRINGEMENT. THE CREATOR ENTITIES MAKE NO WARRANTY OR
          REPRESENTATION AND DISCLAIM ALL RESPONSIBILITY AND LIABILITY FOR: (A)
          THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, ORIGINALITY,
          SECURITY OR RELIABILITY OF THE ROVER SEALS NFTS AND RELATED ART; (B)
          THE OPERATION OR COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY
          PARTICULAR SYSTEM, DEVICE, BLOCKCHAIN, DIGITAL WALLET, HARDWARE OR
          MARKETPLACE; AND (C) WHETHER THE ROVER SEALS NFTS AND RELATED ART WILL
          MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR
          ERROR-FREE BASIS; AND (D) THE DELETION OF, OR THE FAILURE TO STORE OR
          TRANSMIT THE ROVER SEALS NFTS AND RELATED ART. THE ROVER SEALS NFTS
          ARE INTENDED FOR CONSUMER ENJOYMENT, USE AND CONSUMPTION ONLY. THEY
          ARE NOT A “SECURITY,” AS DEFINED UNDER THE SECURITIES ACT OF 1933, AS
          AMENDED, THE SECURITIES EXCHANGE ACT OF 1934, AS AMENDED, THE
          INVESTMENT COMPANY ACT OF 1940, AS AMENDED, UNDER THE SECURITIES LAWS
          OF ANY U.S. STATE, OR OTHER APPLICABLE REGULATIONS.
          <br />
          <br />
          <span className='text-hightlight'>LIMITATIONS OF LIABILITY.</span> TO
          THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE
          CREATOR ENTITIES BE LIABLE (A) FOR ANY INDIRECT, SPECIAL, EXEMPLARY,
          INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT
          LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE,
          DATA OR PROFITS, BUSINESS INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES,
          ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE
          SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER
          UNDER THIS LICENSE OR OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH
          THE SERVICES OR THIS LICENSE AND WHETHER IN CONTRACT, STRICT LIABILITY
          OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN IF THE CREATOR
          ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR (B)
          FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR
          ARISING OUT OF OR IN CONNECTION WITH THIS LICENSE OR THE DELIVERY, USE
          OR PERFORMANCE OF THE SERVICES. THE MAXIMUM AGGREGATE LIABILITY OF THE
          CREATOR ENTITIES FOR ALL DAMAGES AND CAUSES OF ACTION, WHETHER IN
          CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL BE THE
          GREATER OF (I) $US1,000 OR (II) THE AMOUNT YOU PAID TO PURCHASE YOUR
          ROVER SEALS NFT. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          EXCLUSION OR LIMITATION MAY NOT APPLY TO YOU.
          <br />
          <br />
          <span className='text-hightlight'>Indemnification.</span> By entering
          into this License and accessing or using the ROVER SEALS NFT or
          related Art, you agree that you shall defend, indemnify and hold the
          Creator Entities harmless from and against any and all claims, costs,
          damages, losses, liabilities and expenses (including attorneys' fees
          and costs) incurred by the Creator Entities arising out of or in
          connection with: (i) your violation or breach of any term of this
          License or any applicable law or regulation; (ii) your violation of
          any rights of any third party; (iii) your access to or use of the
          ROVER SEALS NFT or related Art; (iv) any modifications to or
          Derivative Works of the Art you create, or (v) any fraud, negligence
          or wilful misconduct committed by you.
        </p>

        <div className='sub-text'>9. TERM AND TERMINATION</div>
        <p>
          The foregoing limited licenses apply only during the License Term.
          These licenses terminate immediately at such a point that the Owner no
          longer owns its ROVER SEALS NFT. Moreover, if you breach any of the
          provisions of this license, (i) all licenses granted by the creator
          will terminate automatically. (ii) in addition to any remedies that
          may be available to Creator at law or in equity, the Creator may
          immediately terminate this License, without the requirement of notice;
          and (iii) you will be responsible to reimburse Creator for any fees,
          costs and expenses incurred by Creator during the course of enforcing
          the terms of this License against you, including but not limited to
          accounting and legal fees, costs and expenses. Upon the termination of
          your licenses, you shall cease all marketing, distribution, or sale of
          goods, services and media that feature the ROVER SEALS Art and shall
          cease all further use of the ROVER SEALS Art. All provisions which by
          their nature should survive the termination of this License shall
          continue in full force and effect subsequent to and notwithstanding
          any termination of this License by Creator or you. 
        </p>

        <div className='sub-text'>10. DISPUTE RESOLUTION</div>
        <p>
          <span className='text-hightlight'>
            Pre-Arbitration Dispute Resolution.
          </span>{' '}
          We are always interested in resolving disputes amicably and
          efficiently, and most customer concerns can be resolved quickly and to
          the customer’s satisfaction by emailing customer support at
          <a href='mailto:legal@finwallet.com' className='text-hightlight'>
            legal@finwallet.com
          </a>
          . If such efforts prove unsuccessful, a party who intends to seek
          arbitration must first send to the other, by certified mail, a written
          Notice of Dispute (a
          <span className='text-hightlight'>“Notice”</span>).  The Notice to Us
          should be sent to Rover Seals by emailing at{' '}
          <a href='mailto:legal@finwallet.com' className='text-hightlight'>
            legal@finwallet.com
          </a>{' '}
          (the <span className='text-hightlight'>“Notice Address”</span>).  The
          Notice must (i) describe the nature and basis of the claim or dispute
          and (ii) set forth the specific relief sought. If such a claim is not
          resolved within sixty (60) calendar days after the Notice is received
          by us, an arbitration proceeding may be commenced. During the
          arbitration, the amount of any settlement offer made by us or you
          shall not be disclosed to the arbitrator until after the arbitrator
          determines the amount, if any, to which you or we are entitled.
          <br />
          <br />
          <span className='text-hightlight'>
            Arbitration Agreement and Procedures.
          </span>{' '}
          You agree that if the Parties are unable to resolve a dispute through
          informal negotiations, the dispute (except those disputes expressly
          excluded below) will be finally and exclusively resolved by binding
          arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
          HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration
          shall be referred to and finally resolved by the Singapore
          International Arbitration Centre (
          <span className='text-hightlight'>“SIAC”</span>) to be resolved
          through confidential, binding arbitration before one arbitrator.
          Arbitration proceedings will be held in Singapore. You agree that
          Disputes will be held in accordance with the SIAC Arbitration Rules
          and Procedures (<span className='text-hightlight'>“SIAC Rules”</span>
          ). The most recent version of the SIAC Rules is incorporated into This
          License by reference. You either acknowledge and agree that you have
          read and understand the SIAC Rules or waive your opportunity to read
          the SIAC Rules and waive any claim that the SIAC Rules are unfair or
          should not apply for any reason. The arbitration hearing shall be
          conducted virtually. The arbitrator will make a decision in writing
          but need not provide a statement of reasons unless requested by either
          Party. The arbitrator must follow applicable law, and any award may be
          challenged if the arbitrator fails to do so. Except where otherwise
          required by the applicable SIAC Rules or applicable law.
          <br />
          <br />
          If for any reason, a dispute proceeds in court rather than
          arbitration, the dispute shall be commenced or prosecuted in the court
          located in Singapore.     
          <br />
          <br />
          <span className='text-hightlight'>Restrictions.</span> The Parties
          agree that any arbitration shall be limited to the dispute between the
          Parties individually. To the full extent permitted by law, (a) no
          arbitration shall be joined with any other proceeding; (b) there is no
          right or authority for any dispute to be arbitrated on a class-action
          basis or to utilize class action procedures; and (c) there is no right
          or authority for any dispute to be brought in a purported
          representative capacity on behalf of the general public or any other
          persons.
          <br />
          <br />
          <span className='text-hightlight'>
            Exceptions to Arbitration.
          </span>{' '}
          The Parties agree that the following disputes are not subject to the
          above provisions concerning binding arbitration: (a) any disputes
          seeking to enforce or protect, or concerning the validity of, any of
          the intellectual property rights of a Party; (b) any dispute related
          to, or arising from, allegations of theft, piracy, invasion of
          privacy, or unauthorized use; and (c) any claim for injunctive relief.
          If this provision is found to be illegal or unenforceable, then
          neither Party will elect to arbitrate any dispute falling within that
          portion of this provision found to be illegal or unenforceable and
          such dispute shall be decided by a court of competent jurisdiction
          within the courts listed for jurisdiction above, and the Parties agree
          to submit to the personal jurisdiction of that court.
        </p>

        <div className='sub-text'>11. MISCELLANEOUS</div>
        <p>
          <ul>
            <li>
              <span className='text-hightlight'>Governing Law.</span> This
              License is governed by and construed in accordance with the laws
              of Singapore, without regard to its conflict of law principles.
            </li>
            <li>
              <span className='text-hightlight'>No Waivers.</span>  No failure
              or delay on our part in the exercise of any power, right,
              privilege or remedy under this License shall operate as a waiver
              of such power, right, privilege or remedy; and no single or
              partial exercise of any such power, right, privilege or remedy
              shall preclude any other or further exercise thereof or of any
              other power, right, privilege or remedy.  We shall not be deemed
              to have waived any claim arising out of this License, or any
              power, right, privilege or remedy under this License, unless the
              waiver of such claim, power, right, privilege or remedy is
              expressly set forth in a written instrument duly executed and
              delivered on behalf of us, and any such waiver shall not be
              applicable or have any effect except in the specific instance in
              which it is given.
            </li>
            <li>
              <span className='text-hightlight'>Entire Agreement.</span> This
              License constitutes the complete agreement between the parties and
              supersedes all prior agreements made between the parties in
              relation to the licenses made under this License.
            </li>
            <li>
              <span className='text-hightlight'>Assignment.</span>  The Creator
              may assign any or all of its rights and obligations under this
              License to others at any time. The Creator shall not be
              responsible or liable for any loss, damage, delay, or failure to
              act caused by any cause beyond its reasonable control. 
            </li>
            <li>
              <span className='text-hightlight'>Severability.</span> If any
              provision or part of a provision of this License is determined to
              be unlawful, void, or unenforceable, that provision or part of the
              provision is deemed severable from this License and does not
              affect the validity and enforceability of any remaining
              provisions. 
            </li>
          </ul>
          <br />
          You agree that this License will not be construed against us by virtue
          of having drafted them. 
        </p>
      </div>
    </LayoutText>
  )
}

export default License
